/* eslint-disable react-hooks/exhaustive-deps */
import React, { PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from "react";
import {
    Address,
    AddressPurpose,
    signMessage,
    getAddress,
    SignMessageOptions,
    SignMultipleTransactionOptions,
    signMultipleTransactions,
    signTransaction,
    SignTransactionOptions,
    request as xverseBTCRequest,
    BitcoinNetworkType,
} from "sats-connect";
// import { TrezorBitcoin } from "@teleportdao/trezor-bitcoin";
import * as xdefiBTC from "utils/xdefi";

import isEmpty from "lodash/isEmpty";

import {
    isTestnet,
    unisatWalletLink,
    xverseWalletLink,
    xdefiWalletLink,
    okxWalletLink,
    mempoolAPI,
    baseAPI,
    sourceNetwork,
    snapType,
    bip32NetworkConfig,
    phantomWalletLink,
    leatherWalletLink,
    snapId,
    snapWalletLink,
    magicedenWalletLink,
} from "utils/configs";
import usePrice from "services/api/usePrice";
import { useQuery } from "@tanstack/react-query";
import useBitcoin from "utils/useBitcoin";
import { parseCustomTokenValue } from "utils/convert";
import useRequest from "utils/useRequest";
import { useGlobalState } from "./globalContext";
import { sleep } from "utils/functions";
import { TeleswapWallet, bitcoinUtils } from "@teleportdao/bitcoin";

declare const window: any;

export interface SignPsbtRequestParamsLeatherWallet {
    hex: string;
    allowedSighash?: any[]; //SignatureHash[]
    signAtIndex?: number | number[];
    // network?: NetworkModes;         // default is user's current network
    account?: number; // default is user's current account
    broadcast?: boolean; // default is false - finalize/broadcast tx
}

export function walletTypeToTitle(type: AddressType) {
    if (type?.includes("p2wpkh")) {
        return "Native SegWit";
    }
    if (type?.includes("p2sh")) {
        return "Nested SegWit";
    }
    if (type?.includes("p2tr")) {
        return "Taproot";
    }
    return "";
}

export enum AddressType {
    XVerse = "XVerse",
    "XVerse-p2wpkh" = "XVerse-p2wpkh",
    "XVerse-p2sh" = "XVerse-p2sh",
    "XVerse-p2tr" = "XVerse-p2tr",
    MagicEden = "MagicEden",
    "MagicEden-p2wpkh" = "MagicEden-p2wpkh",
    "MagicEden-p2sh" = "MagicEden-p2sh",
    "MagicEden-p2tr" = "MagicEden-p2tr",
    Unisat = "Unisat",
    OKX = "OKX",
    XDefi = "XDefi",
    "XDefi-p2wpkh" = "XDefi-p2wpkh",
    "XDefi-p2pkh" = "XDefi-p2pkh",
    Liquality = "Liquality",
    Snap = "Snap",
    "Snap-p2wpkh" = "Snap-p2wpkh",
    "Snap-p2pkh" = "Snap-p2pkh",
    "Snap-p2tr" = "Snap-p2tr",
    Phantom = "Phantom",
    "Phantom-p2wpkh" = "Phantom-p2wpkh",
    "Phantom-p2tr" = "Phantom-p2tr",
    Ledger = "Ledger",
    Trezor = "Trezor",
    "Trezor-p2wpkh" = "Trezor-p2wpkh",
    "Trezor-p2pkh" = "Trezor-p2tr",
    Leather = "Leather",
    "Leather-p2wpkh" = "Leather-p2wpkh",
    "Leather-p2tr" = "Leather-p2tr",
}

export interface Wallet {
    address: string;
    publicKey: string;
    masterFingerprint?: string;
    derivationPath?: string;
    type: AddressType;
    icon: string;
}

export const fromHexString = (hexString: any) =>
    Uint8Array.from(hexString.match(/.{1,2}/g).map((byte: any) => parseInt(byte, 16)));

export const bytesToBase64 = (bytes: any) => {
    const binString = String.fromCodePoint(...bytes);
    return btoa(binString);
};

export interface IBitcoinWalletContext {
    connect: (walletType: AddressType) => Promise<Wallet>;
    disconnect: () => Promise<void>;
    switchNetwork: () => Promise<void>;
    signMessage: (message: string) => Promise<void>;
    signPSBT: (data: any) => Promise<void>;
    multiSignPSBT: (data: any) => Promise<void>;
    signPSBTByType: (data: any, type: string) => Promise<void>;
    multiSignPSBTByType: (data: any, type: string) => Promise<void>;
    getBalance: () => Promise<void>;
    sendInscription: (toAddress: string, inscriptionId: string, location: string) => Promise<string>;
    getInscriptions: () => Promise<any>;
    setWallet: (wallet: Wallet) => void;
    installed: boolean;
    connected: boolean;
    wallet: Wallet;
    accounts: any[];
    balance: string | number;
    network: string;
    btcUSDPrice: string | number;
    instantApp?: any;
}

const initialValue: IBitcoinWalletContext = {
    connect: (walletType: AddressType) => new Promise<Wallet>(() => null),
    disconnect: () => new Promise<void>(() => null),
    switchNetwork: () => new Promise<void>(() => null),
    signMessage: (message: string) => new Promise<void>(() => null),
    signPSBT: (data: any) => new Promise<void>(() => null),
    multiSignPSBT: (data: any) => new Promise<void>(() => null),
    signPSBTByType: (data: any, type: string) => new Promise<void>(() => null),
    multiSignPSBTByType: (data: any, type: string) => new Promise<void>(() => null),
    getBalance: () => new Promise<void>(() => null),
    sendInscription: (toAddress: string, inscriptionId: string, location: string) => new Promise<string>(() => null),
    getInscriptions: () => new Promise<any>(() => null),
    setWallet: (wallet: Wallet) => {},
    installed: false,
    connected: false,
    accounts: [],
    balance: 0,
    wallet: {} as Wallet,
    network: isTestnet ? "Testnet" : "Mainnet",
    btcUSDPrice: 0,
    instantApp: null,
};

const BitcoinWalletContext = React.createContext<IBitcoinWalletContext>(initialValue);

const BitcoinWalletProvider: React.FC<PropsWithChildren> = ({ children }: PropsWithChildren): React.ReactElement => {
    let _window: any = null;
    _window = typeof window === "undefined" ? null : window;
    const { ledgerBitcoinApp, trezorBitcoinApp } = useGlobalState();
    const [installed, setInstalled] = useState(false);
    const [connected, setConnected] = useState(false);
    const [wallet, setWallet] = useState<Wallet>({} as Wallet);
    const [instantApp, setInstantApp] = useState<any>(null);
    const [accounts, setAccounts] = useState<any>([]);
    const [balance, setBalance] = useState<string | number>(0);
    const [network, setNetwork] = useState(isTestnet ? "Testnet" : "Mainnet");
    const { getTokenPrice } = usePrice();
    const request = useRequest();
    const { createNFTPsbt, sendBitcoinTx, getInscriptionsAndPaymentsUtxo } = useBitcoin();

    const selfRef = useRef<{ accounts: string[]; wallet: Wallet }>({
        accounts: [],
        wallet: {} as Wallet,
    });
    const self = selfRef.current;

    const unisat = (_window as any)?.unisat;
    const okxWallet = (_window as any)?.okxwallet?.bitcoin;
    const phantomWallet = (_window as any)?.phantom?.bitcoin;
    const xdefiWallet = (_window as any)?.xfi?.bitcoin;
    const xverseWallet = (_window as any)?.BitcoinProvider;
    const magicedenWallet = (_window as any)?.magicEden?.bitcoin;
    const liqualityWallet = (_window as any)?.bitcoin;
    const leatherProvider = (_window as any)?.LeatherProvider;

    const getBip32Network = (addressType: any) => {
        return bip32NetworkConfig[addressType];
    };
    const createAddressObjectByPublicKey = (publicKey: any, addressType: any) => {
        const bip32Network = getBip32Network(addressType);
        try {
            const deriveAddress = bitcoinUtils?.createAddressObjectByPublicKey(
                { addressType: addressType, publicKey: publicKey },
                bip32Network,
            );
            return deriveAddress;
        } catch (error) {
            return publicKey;
        }
    };
    const getPublicKeyFromXpub = (addressX: string, addressType: any) => {
        const bip32Network = getBip32Network(addressType);
        const pubkey = bitcoinUtils.getPublicKeyHexByXpubAndIndex(addressX, 0, false, bip32Network);
        const addressObject = createAddressObjectByPublicKey(Buffer.from(pubkey, "hex"), addressType);
        return { ...addressObject, publicKey: pubkey };
    };

    const getUtxoProxy = async (address: string) => {
        const utxoResult: any = await request.get(`${baseAPI}/proxy/get-utxo/${address}`);
        return utxoResult?.data;
    };
    const getUtxoAllProxy = async (addresses: string[]) => {
        const utxoPromises = addresses.map((address) => getUtxoProxy(address));
        const utxoList = (await Promise.all(utxoPromises)).flat(1);
        return utxoList;
    };
    const getBalanceProxy = async (address: string) => {
        if (!address) {
            return 0;
        }
        const balanceResult: any = await request.get(`${baseAPI}/proxy/get-utxo/${address}`);
        const balanceList = (balanceResult?.data || [])?.reduce((all: any, curr: any) => {
            return all.concat(curr?.value);
        }, []);
        const balance = balanceList.reduce((result: any, value: any) => result + value, 0);
        const parseBalance = parseCustomTokenValue(balance, 8);

        setBalance(parseBalance);
        return parseBalance;
    };
    const getBalanceAllProxy = async (addresses: string[]) => {
        const utxoPromises = addresses.map((address) => getUtxoProxy(address));
        const utxoList = (await Promise.all(utxoPromises)).flat(1);

        const balanceList = (utxoList || [])?.reduce((all: any, curr: any) => {
            return all.concat(curr?.value);
        }, []);

        const balance = balanceList.reduce((result: any, value: any) => result + value, 0);
        const parseBalance = parseCustomTokenValue(balance, 8);

        setBalance(parseBalance);
        return parseBalance;
    };
    //magiceden
    //https://docs-wallet.magiceden.io/bitcoin/signing-a-transaction
    const checkInstalledMagicEden = () => {
        const walletInstalled = (_window as any)?.magicEden?.bitcoin;
        if (!walletInstalled) {
            setInstalled(false);
            const previouslyConnectedBTCWallets =
                (_window && _window.JSON.parse(window?.localStorage?.getItem("connectedBTCWallets"))) || [];
            !previouslyConnectedBTCWallets?.includes(AddressType.MagicEden) &&
                _window.open(magicedenWalletLink, "_blank");
        } else {
            setInstalled(true);
        }

        return !!walletInstalled;
    };

    const getMagicEdenProvider = () => {
        if ("magicEden" in window) {
            const anyWindow: any = window;
            if (anyWindow?.magicEden?.bitcoin && anyWindow?.magicEden?.bitcoin?.isMagicEden)
                return anyWindow?.magicEden?.bitcoin;
        }
        _window.open(magicedenWalletLink, "_blank");
    };

    const getAddressObjectByAddress = async (address: string): Promise<any> => {
        const responseAddressObject = await request.get(
            `${baseAPI}/utils/bitcoin/address/${address}?${isTestnet ? "testnet=true" : "testnet=false"}`,
        );
        return responseAddressObject?.data;
    };
    const connectMagicEden = async (walletType: AddressType) => {
        try {
            const walletInstalled = checkInstalledMagicEden();
            if (!walletInstalled) return;

            const response = await new Promise((resolve: (response: any) => void, reject) => {
                return getAddress({
                    getProvider: getMagicEdenProvider,
                    payload: {
                        purposes: [AddressPurpose.Payment, AddressPurpose.Ordinals],
                        message: "Address for receiving Ordinals and payments",
                        network: {
                            type: isTestnet ? BitcoinNetworkType.Testnet : BitcoinNetworkType.Mainnet,
                        },
                    },
                    onFinish: (response) => {
                        resolve(response);
                    },
                    onCancel: () => {
                        reject();
                    },
                });
            });

            const __accounts = (response?.addresses as Address[]) || [];
            const addressObject0: any = await getAddressObjectByAddress(__accounts[0]?.address);
            const addressObject1: any = await getAddressObjectByAddress(__accounts[1]?.address);
            const _accounts = [
                { ...__accounts[0], ...addressObject0 },
                { ...__accounts[1], ...addressObject1 },
            ];

            if (!!_accounts && !isEmpty(_accounts)) {
                const account = _accounts.find(
                    (_account) => _account.addressType === walletType.replace(AddressType.MagicEden + "-", ""),
                );
                if (account) {
                    setWallet({
                        address: account.address,
                        publicKey: account.publicKey,
                        type: walletType,
                        icon: "magiceden",
                    });
                    self.wallet = {
                        address: account.address,
                        publicKey: account.publicKey,
                        type: walletType,
                        icon: "magiceden",
                    };

                    getBalanceProxy(account.address);

                    setAccounts(_accounts);

                    setConnected(true);

                    const connectedBTCWallets = [walletType];

                    window && _window.localStorage.setItem("connectedBTCWallets", JSON.stringify(connectedBTCWallets));
                } else {
                    throw new Error(
                        `Choose ${walletTypeToTitle(walletType)} as your preferred Bitcoin address in your wallet.`,
                    );
                }
            }
        } catch (e) {
            throw e;
        }
    };
    const disconnectMagicEden = async () => {
        // await xverseBTC.disconnect().then((res) => {});
        setConnected(false);
        setWallet({} as Wallet);
        self.wallet = {} as Wallet;
        setAccounts([]);
        setBalance(0);
        setNetwork(isTestnet ? "Testnet" : "Mainnet");
        self.accounts = [];
        window && _window.localStorage.removeItem("connectedBTCWallets");
    };
    const signMessageMagicEden = async (message: string) => {
        const signature = await new Promise((resolve: (response: string) => void, reject) => {
            const signMessageOptions = {
                getProvider: getMagicEdenProvider,
                payload: {
                    address: wallet?.address,
                    message: message,
                    network: {
                        type: network,
                    },
                },
                onFinish: (response: string) => {
                    resolve(response);
                },
                onCancel: (_: unknown) => {
                    reject(_);
                },
            } as SignMessageOptions;

            return signMessage(signMessageOptions).catch((error) => {
                reject(error);
            });
        });
        return signature;
    };
    const signPSBTMagicEden = async (data: any) => {
        const inputsToSign = (data?.toSignInputs || [])?.map((input: any) => ({
            signingIndexes: [input.index],
            address: input.address,
            ...(input?.sighashTypes && { sigHash: input.sighashTypes[0] }),
        }));

        const signedPSBTBase64OrTxId = await new Promise((resolve: (response: any) => any, reject) => {
            const signPSBTOptions = {
                getProvider: getMagicEdenProvider,
                payload: {
                    network: {
                        type: network,
                        // ...(data?.signerInfo && { address: data?.signerInfo.address || "" }),
                    },
                    message: "Sign Transaction",
                    psbtBase64: data.unsignedPSBTBase64,
                    broadcast: false,
                    ...(data?.toSignInputs && { inputsToSign }),
                },
                onFinish: (response: any) => {
                    resolve(response.psbtBase64);
                },
                onCancel: () => {
                    reject("User Declined");
                },
            } as SignTransactionOptions;

            return signTransaction(signPSBTOptions).catch((error) => {
                reject(error);
            });
        });
        const btcTransaction = new TeleswapWallet(sourceNetwork.name);
        const txId = await btcTransaction.sendSignedPsbt(signedPSBTBase64OrTxId);
        return txId;
    };
    const multiSignPSBTMagicEden = async (data: any) => {
        const psbts = data.map((i: any) => {
            const inputsToSign = (i?.toSignInputs || [])?.map((input: any) => ({
                signingIndexes: [input.index],
                address: input.address,
                ...(i?.sighashTypes && { sigHash: input.sighashTypes[0] }),
            }));
            return {
                psbtBase64: i.unsignedPSBTBase64,
                ...(i?.toSignInputs && { inputsToSign }),
            };
        });

        const signedPSBTsBase64 = await new Promise((resolve: (response: any) => any, reject) => {
            const signPSBTOptions = {
                getProvider: getMagicEdenProvider,
                payload: {
                    network: {
                        type: network,
                        ...(data?.signerInfo && { address: data?.signerInfo.address || "" }),
                    },
                    message: "Sign Transaction",
                    psbts,
                },
                onFinish: (responses: any) => {
                    const signedPSBTsBase64 = responses.map((response: any) => {
                        return response?.psbtBase64;
                    });
                    resolve(signedPSBTsBase64);
                },
                onCancel: () => {
                    reject();
                },
            } as SignMultipleTransactionOptions;

            return signMultipleTransactions(signPSBTOptions).catch((error) => {
                reject(error);
            });
        });

        if (data?.[0]?.autoFinalized && data?.[0]?.autoFinalized === true) {
            let txIds = [];
            for (const signedPSBTBase64 of signedPSBTsBase64) {
                const btcTransaction = new TeleswapWallet(sourceNetwork.name);
                const txId = await btcTransaction.sendSignedPsbt(signedPSBTBase64);
                await sleep(3000, () => {});
                txIds.push(txId);
            }
            return txIds;
        } else {
            return signedPSBTsBase64;
        }
    };
    //XVerse
    //https://docs.xverse.app/sats-connect-v1/methods/signtransaction  Old Doc
    //https://docs.xverse.app/sats-connect    New Doc
    const checkInstalledXVerse = () => {
        const walletInstalled = (_window as any)?.BitcoinProvider;
        if (!walletInstalled) {
            setInstalled(false);
            const previouslyConnectedBTCWallets =
                (_window && _window.JSON.parse(window?.localStorage?.getItem("connectedBTCWallets"))) || [];
            !previouslyConnectedBTCWallets?.includes(AddressType.XVerse) && _window.open(xverseWalletLink, "_blank");
        } else {
            setInstalled(true);
        }

        return !!walletInstalled;
    };
    const connectXVerse = async (walletType: AddressType) => {
        try {
            const walletInstalled = checkInstalledXVerse();
            if (!walletInstalled) return;

            const response: any = await xverseBTCRequest("getAccounts", {
                purposes: [AddressPurpose.Payment, AddressPurpose.Ordinals],
                message: "Cool app wants to know your addresses!",
            });

            const _accounts = response.result as Address[];
            if (!!_accounts && !isEmpty(_accounts)) {
                const account = _accounts.find(
                    (_account) => _account.addressType === walletType.replace(AddressType.XVerse + "-", ""),
                );
                if (account) {
                    setWallet({
                        address: account.address,
                        publicKey: account.publicKey,
                        type: walletType,
                        icon: "xverse",
                    });
                    self.wallet = {
                        address: account.address,
                        publicKey: account.publicKey,
                        type: walletType,
                        icon: "xverse",
                    };

                    getBalanceProxy(account.address);

                    setAccounts(_accounts);

                    setConnected(true);

                    const connectedBTCWallets = [walletType];

                    window && _window.localStorage.setItem("connectedBTCWallets", JSON.stringify(connectedBTCWallets));
                } else {
                    throw new Error(
                        `Choose ${walletTypeToTitle(walletType)} as your preferred Bitcoin address in your wallet.`,
                    );
                }
            }
        } catch (e) {
            throw e;
        }
    };
    const disconnectXVerse = async () => {
        // await xverseBTC.disconnect().then((res) => {});
        setConnected(false);
        setWallet({} as Wallet);
        self.wallet = {} as Wallet;
        setAccounts([]);
        setBalance(0);
        setNetwork(isTestnet ? "Testnet" : "Mainnet");
        self.accounts = [];
        window && _window.localStorage.removeItem("connectedBTCWallets");
    };
    const signMessageXVerse = async (message: string) => {
        const signature = await new Promise((resolve: (response: string) => void, reject) => {
            const signMessageOptions = {
                payload: {
                    address: wallet?.address,
                    message: message,
                    network: {
                        type: network,
                    },
                },
                onFinish: (response: string) => {
                    resolve(response);
                },
                onCancel: (_: unknown) => {
                    reject(_);
                },
            } as SignMessageOptions;

            return signMessage(signMessageOptions).catch((error) => {
                reject(error);
            });
        });
        return signature;
    };
    const signPSBTXVerse = async (data: any) => {
        const inputsToSign = (data?.toSignInputs || [])?.map((input: any) => ({
            signingIndexes: [input.index],
            address: input.address,
            ...(input?.sighashTypes && { sigHash: input.sighashTypes[0] }),
        }));

        const signedPSBTBase64OrTxId = await new Promise((resolve: (response: any) => any, reject) => {
            const signPSBTOptions = {
                payload: {
                    network: {
                        type: network,
                        // ...(data?.signerInfo && { address: data?.signerInfo.address || "" }),
                    },
                    message: "Sign Transaction",
                    psbtBase64: data.unsignedPSBTBase64,
                    broadcast: data?.autoFinalized ? data?.autoFinalized : false,
                    ...(data?.toSignInputs && { inputsToSign }),
                },
                onFinish: (response: any) => {
                    resolve(data?.autoFinalized && data?.autoFinalized == true ? response.txId : response.psbtBase64);
                },
                onCancel: () => {
                    reject("User Declined");
                },
            } as SignTransactionOptions;

            return signTransaction(signPSBTOptions).catch((error) => {
                reject(error);
            });
        });
        return signedPSBTBase64OrTxId;
    };
    const multiSignPSBTXVerse = async (data: any) => {
        const psbts = data.map((i: any) => {
            const inputsToSign = (i?.toSignInputs || [])?.map((input: any) => ({
                signingIndexes: [input.index],
                address: input.address,
                ...(i?.sighashTypes && { sigHash: input.sighashTypes[0] }),
            }));
            return {
                psbtBase64: i.unsignedPSBTBase64,
                ...(i?.toSignInputs && { inputsToSign }),
            };
        });

        const signedPSBTsBase64 = await new Promise((resolve: (response: any) => any, reject) => {
            const signPSBTOptions = {
                payload: {
                    network: {
                        type: network,
                        ...(data?.signerInfo && { address: data?.signerInfo.address || "" }),
                    },
                    message: "Sign Transaction",
                    psbts,
                },
                onFinish: (responses: any) => {
                    const signedPSBTsBase64 = responses.map((response: any) => {
                        return response?.psbtBase64;
                    });
                    resolve(signedPSBTsBase64);
                },
                onCancel: () => {
                    reject();
                },
            } as SignMultipleTransactionOptions;

            return signMultipleTransactions(signPSBTOptions).catch((error) => {
                reject(error);
            });
        });

        if (data?.[0]?.autoFinalized && data?.[0]?.autoFinalized === true) {
            let txIds = [];
            for (const signedPSBTBase64 of signedPSBTsBase64) {
                const btcTransaction = new TeleswapWallet(sourceNetwork.name);
                const txId = await btcTransaction.sendSignedPsbt(signedPSBTBase64);
                await sleep(3000, () => {});
                txIds.push(txId);
            }
            return txIds;
        } else {
            return signedPSBTsBase64;
        }
    };
    const sendInscriptionWithSignPSBT = async (receiverAddress: string, inscriptionId: string, location: string) => {
        const changeAddress = wallet?.address;
        const _extendedUtxo: any = await getInscriptionsAndPaymentsUtxo(wallet, location);
        const tempPaymentsUtxo: any = _extendedUtxo.tempPaymentsUtxo;
        const tempInscriptionsUtxo: any = _extendedUtxo.tempInscriptionsUtxo;
        const extendedUtxo = tempPaymentsUtxo;
        const nftExtendedUtxo = tempInscriptionsUtxo[0];

        let feeRate = 40;
        try {
            const feeRateResponse = await fetch(`${mempoolAPI}/v1/fees/recommended`).then((res) => res.json());
            feeRate = Math.ceil(feeRateResponse?.fastestFee || 40);
        } catch (e) {}
        const responseUnsignedTX = await createNFTPsbt({
            receiverAddress,
            changeAddress,
            extendedUtxo,
            nftExtendedUtxo,
            feeRate,
        });

        const inputs = responseUnsignedTX?.data.inputs.map((i: any, index: number) => ({
            index: index,
            address: i.signerInfo.address,
            publicKey: i.signerInfo.publicKey,
        }));

        const transferTxId: any = await signPSBT({
            unsignedPSBTHEX: Buffer.from(responseUnsignedTX?.data?.unsignedTransaction, "base64").toString("hex"),
            unsignedPSBTBase64: responseUnsignedTX?.data?.unsignedTransaction,
            toSignInputs: inputs,
            autoFinalized: true,
        });
        console.log("sendInscriptionWithSignPSBTTxId:", transferTxId);
        return transferTxId;
    };

    //XDefi
    //https://docs.xdefi.io/docs/technical-documentation/xdefi-extension-integration/bitcoin //old
    //https://developers.xdefi.io/developers/extension-bitcoin
    const checkInstalledXDefi = () => {
        const walletInstalled = (_window as any)?.xfi?.bitcoin;
        if (!walletInstalled) {
            setInstalled(false);
            const previouslyConnectedBTCWallets =
                (_window && _window.JSON.parse(window?.localStorage?.getItem("connectedBTCWallets"))) || [];
            !previouslyConnectedBTCWallets?.includes(AddressType.XDefi) && _window.open(xdefiWalletLink, "_blank");
        } else {
            setInstalled(true);
        }

        return !!walletInstalled;
    };
    const connectXDefi = async (walletType: AddressType) => {
        try {
            const walletInstalled = checkInstalledXDefi();
            if (!walletInstalled) return;
            const getAddressOptions = {
                payload: {
                    purposes: ["ordinals", "payment"],
                    message: "Address for receiving Ordinals",
                    network: {
                        type: network,
                    },
                },
                onFinish: (response: never) => {
                    const _accounts = response as { addresses: Address[] };
                    if (!!_accounts && !isEmpty(_accounts.addresses)) {
                        const account = _accounts.addresses?.[0];
                        // const account = _accounts.addresses.find(
                        //     (_account) => _account.addressType === walletType.replace(AddressType.XDefi + "-", ""),
                        // );
                        if (account) {
                            setWallet({
                                address: account.address,
                                publicKey: account.publicKey,
                                type: walletType,
                                icon: "xdefi",
                            });
                            self.wallet = {
                                address: account.address,
                                publicKey: account.publicKey,
                                type: walletType,
                                icon: "xdefi",
                            };

                            getBalanceProxy(account.address);

                            setAccounts(_accounts.addresses);

                            setConnected(true);

                            const connectedBTCWallets = [walletType];

                            window &&
                                _window.localStorage.setItem(
                                    "connectedBTCWallets",
                                    JSON.stringify(connectedBTCWallets),
                                );
                        } else {
                            throw new Error(
                                `Choose ${walletTypeToTitle(walletType)} as your preferred Bitcoin address in your wallet.. `,
                            );
                        }
                    }
                },
                onCancel: (_: unknown) => {},
            } as xdefiBTC.GetAddressOptions;

            await xdefiBTC.getAddress(getAddressOptions);
        } catch (e) {
            throw e;
        }
    };
    const disconnectXDefi = async () => {
        setConnected(false);
        setWallet({} as Wallet);
        self.wallet = {} as Wallet;
        setAccounts([]);
        setBalance(0);
        setNetwork(isTestnet ? "Testnet" : "Mainnet");
        self.accounts = [];
        window && _window.localStorage.removeItem("connectedBTCWallets");
    };
    const handleAccountsChangedXDefi = (_accounts: string[]) => {
        if (self.wallet?.type !== "XDefi" || !self.wallet?.type) {
            if (self.accounts[0] === _accounts[0]) {
                // prevent from triggering twice
                return;
            }
            self.accounts = _accounts;
            if (_accounts.length > 0) {
                setAccounts(_accounts);
                // getBasicInfoUnisat();
            } else {
                disconnectUnisat();
            }
        }
    };
    // const handleNetworkChangedXDefi = (network: string) => {
    //     if (self.wallet?.type !== "XDefi" || !self.wallet?.type) {
    //         connectXDefi();
    //     }
    // };
    const signMessageXDefi = async (message: string) => {
        const signature = await new Promise((resolve: (response: string) => void, reject) => {
            const signMessageOptions = {
                payload: {
                    address: wallet?.address,
                    message: message,
                    network: {
                        type: network,
                        address: "",
                    },
                },
                onFinish: (response: string) => {
                    resolve(response);
                },
                onCancel: (_: unknown) => {
                    reject(_);
                },
            } as xdefiBTC.SignMessageOptions;

            return xdefiBTC.signMessage(signMessageOptions).catch((error) => {
                reject(error);
            });
        });
        return signature;
    };
    const signPSBTXDefi = async (data: any) => {
        const inputsToSign = (data?.toSignInputs || [])?.map((input: any) => ({
            signingIndexes: [input.index],
            address: input.address,
            ...(input?.sighashTypes && { sigHash: input.sighashTypes[0] }),
        }));
        const signedPSBTBase64 = await new Promise((resolve: (response: any) => any, reject) => {
            const signPSBTOptions = {
                payload: {
                    network: {
                        type: network,
                        ...(data?.signerInfo && { address: data?.signerInfo.address || "" }),
                    },
                    message: "Sign Transaction",
                    psbtBase64: data.unsignedPSBTBase64,
                    // broadcast: data?.autoFinalized ? data?.autoFinalized : false,
                    ...(data?.toSignInputs && { inputsToSign }),
                },
                onFinish: (response: any) => {
                    resolve(response.psbtBase64);
                },
                onCancel: () => {
                    reject();
                },
            } as xdefiBTC.SignTransactionOptions;

            return xdefiBTC.signTransaction(signPSBTOptions).catch((error) => {
                reject(error);
            });
        });
        const btcTransaction = new TeleswapWallet(sourceNetwork.name);

        const txId = data?.autoFinalized ? await btcTransaction.sendSignedPsbt(signedPSBTBase64) : null;
        return data?.autoFinalized ? txId : signedPSBTBase64;
    };

    //Unisat
    //https://docs.unisat.io/dev/unisat-developer-service/unisat-wallet#signpsbt
    const checkInstalledUnisat = () => {
        const walletInstalled = unisat;
        if (!walletInstalled) {
            setInstalled(false);

            const previouslyConnectedBTCWallets =
                (_window && _window.JSON.parse(window?.localStorage?.getItem("connectedBTCWallets"))) || [];

            !previouslyConnectedBTCWallets?.includes(AddressType.Unisat) && _window.open(unisatWalletLink, "_blank");
        } else {
            setInstalled(true);
        }

        return !!walletInstalled;
    };
    const getBasicInfoUnisat = async () => {
        try {
            _window = typeof window === "undefined" ? null : window;
            const walletProvider = (_window as any)?.unisat;

            const chain = await walletProvider.getChain();

            if (isTestnet && chain?.enum === "BITCOIN_TESTNET") {
                throw new Error(`Switch Network to Testnet V4 `);
            }

            const _network = await walletProvider.getNetwork();
            const __network = _network === "livenet" ? "Mainnet" : "Testnet";

            if (network !== __network) {
                await walletProvider.switchNetwork(__network === "Testnet" ? "livenet" : "testnet");
                const _newNetwork = await walletProvider.getNetwork();
                const __newNetwork = _newNetwork === "livenet" ? "Mainnet" : "Testnet";
                if (network !== __newNetwork) {
                    throw new Error(`Switch Network to ${network === "Testnet" ? "livenet " : "testnet"} `);
                }
            }

            const accounts = await walletProvider.getAccounts();

            const publicKey = await walletProvider.getPublicKey();

            setWallet({
                address: accounts[0],
                publicKey: publicKey,
                type: AddressType.Unisat,
                icon: "unisat",
            });
            self.wallet = {
                address: accounts[0],
                publicKey: publicKey,
                type: AddressType.Unisat,
                icon: "unisat",
            };

            setConnected(true);

            const _accounts = (accounts || [])?.map((account: any) => ({
                address: account,
                publicKey: publicKey,
            }));
            setAccounts(_accounts);

            // await getBalanceUnisat();
            getBalanceProxy(accounts[0].address);

            const connectedBTCWallets = [AddressType.Unisat];

            window && _window.localStorage.setItem("connectedBTCWallets", JSON.stringify(connectedBTCWallets));
        } catch (e) {
            throw e;
        }
    };
    const handleAccountsChangedUnisat = (_accounts: string[]) => {
        if (self.wallet?.type === "Unisat") {
            if (self.accounts[0] === _accounts[0]) {
                // prevent from triggering twice
                return;
            }
            self.accounts = _accounts;
            if (_accounts.length > 0) {
                setAccounts(_accounts);
                getBasicInfoUnisat();
            } else {
                disconnectUnisat();
            }
        }
    };
    const connectUnisat = async () => {
        try {
            _window = typeof window === "undefined" ? null : window;
            const walletProvider = (_window as any)?.unisat;

            const walletInstalled = checkInstalledUnisat();
            if (!walletInstalled) return;
            const result = await walletProvider.requestAccounts();
            await getBasicInfoUnisat();
        } catch (e) {
            throw e;
        }
    };
    const disconnectUnisat = async () => {
        setConnected(false);
        setWallet({} as Wallet);
        self.wallet = {} as Wallet;
        self.accounts = [];
        setAccounts([]);
        setNetwork(isTestnet ? "Testnet" : "Mainnet");
        setBalance(0);
        self.accounts = [];

        window && _window.localStorage.removeItem("connectedBTCWallets");
    };
    const handleNetworkChangedUnisat = (network: string) => {
        if (self.wallet?.type === "Unisat") {
            connectUnisat();
        }
    };
    const signMessageUnisat = async (message: string) => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = (_window as any)?.unisat;
        const signature = await walletProvider.signMessage(message);
        return signature;
    };
    const sendInscriptionUnisat = async (toAddress: string, inscriptionId: string) => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = (_window as any)?.unisat;
        let feeRate = 40;
        try {
            const feeRateResponse = await fetch(`${mempoolAPI}/v1/fees/recommended`).then((res) => res.json());
            feeRate = Math.ceil(feeRateResponse?.fastestFee || 40);
        } catch (e) {}

        const transferTxId = await walletProvider.sendInscription(toAddress, inscriptionId, { feeRate }); // TODO calc fee rate from unisat
        console.log("sendInscriptionUnisatTxId:", transferTxId);
        return transferTxId;
    };
    const getInscriptionsUnisat = async () => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = (_window as any)?.unisat;
        const inscriptions = await walletProvider.getInscriptions(0, 100);
        return inscriptions?.list || [];
    };
    const getBalanceUnisat = async () => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = (_window as any)?.unisat;
        const balance = await walletProvider.getBalance();
        const parseBalance = parseCustomTokenValue(balance?.total, 8);
        setBalance(parseBalance);
        return parseBalance;
    };
    const getInscriptionsFake = async () => {
        return [];
    };
    const signPSBTUnisat = async (data: any) => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = (_window as any)?.unisat;

        const inputsToSign = data?.toSignInputs?.map((input: any) => ({
            index: input.index,
            address: input.address,
            publicKey: input.publicKey,
            ...(input?.sighashTypes && { sighashTypes: input.sighashTypes }),
            ...(input?.disableTweakSigner && { disableTweakSigner: input.disableTweakSigner }),
        }));

        const signedPSBTHex = await walletProvider.signPsbt(data.unsignedPSBTHEX, {
            autoFinalized: data?.autoFinalized ? data?.autoFinalized : false,
            ...(data?.toSignInputs && { toSignInputs: inputsToSign }),
        });

        if (data?.autoFinalized && data?.autoFinalized === true) {
            const txId = await walletProvider.pushPsbt(signedPSBTHex);
            return txId;
        } else {
            const signedPSBTBase64 = Buffer.from(signedPSBTHex, "hex").toString("base64");
            return signedPSBTBase64;
        }
    };
    const multiSignPSBTUnisat = async (data: any) => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = (_window as any)?.unisat;

        const unsignedPSBTsHEX = data.map((i: any) => i.unsignedPSBTHEX);
        const options = data.map((i: any) => {
            const inputsToSign = i?.toSignInputs?.map((input: any) => ({
                index: input.index,
                address: input.address,
                publicKey: input.publicKey,
                ...(input?.sighashTypes && { sighashTypes: input.sighashTypes }),
                ...(input?.disableTweakSigner && { disableTweakSigner: input.disableTweakSigner }),
            }));
            return {
                autoFinalized: i?.autoFinalized ? i?.autoFinalized : false,
                ...(i?.toSignInputs && { toSignInputs: inputsToSign }),
            };
        });
        const signedPSBTsHex = await walletProvider.signPsbts(unsignedPSBTsHEX, options);

        if (data?.[0]?.autoFinalized && data?.[0]?.autoFinalized === true) {
            let txIds = [];
            for (const signedPSBTHex of signedPSBTsHex) {
                const txId = await walletProvider.pushPsbt(signedPSBTHex);
                await sleep(3000, () => {});
                txIds.push(txId);
            }
            return txIds;
        } else {
            const signedPSBTsBase64 = signedPSBTsHex.map((signedPSBTHex: any) => {
                const signedPSBTBase64 = Buffer.from(signedPSBTHex, "hex").toString("base64");
                return signedPSBTBase64;
            });
            return signedPSBTsBase64;
        }
    };
    //OKX
    //https://www.okx.com/web3/build/docs/sdks/chains/bitcoin/provider#signing-psbt
    const checkInstalledOKX = () => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = (_window as any)?.okxwallet?.bitcoin;
        const walletInstalled = walletProvider;
        if (!walletInstalled) {
            setInstalled(false);
            const previouslyConnectedBTCWallets =
                (_window && _window.JSON.parse(window?.localStorage?.getItem("connectedBTCWallets"))) || [];
            !previouslyConnectedBTCWallets?.includes(AddressType.OKX) && _window.open(okxWalletLink, "_blank");
        } else {
            setInstalled(true);
        }

        return !!walletInstalled;
    };
    const getBasicInfoOKX = async (result: any) => {
        if (!!result && !isEmpty(result.address)) {
            const account = result;
            if (account) {
                setWallet({
                    address: account.address,
                    publicKey: account.publicKey,
                    type: AddressType.OKX,
                    icon: "okx",
                });
                self.wallet = {
                    address: account.address,
                    publicKey: account.publicKey,
                    type: AddressType.OKX,
                    icon: "okx",
                };

                await getBalanceOKX();

                setConnected(true);
                setAccounts([account]);

                const connectedBTCWallets = [AddressType.OKX];

                window && _window.localStorage.setItem("connectedBTCWallets", JSON.stringify(connectedBTCWallets));
            }
        } else {
            disconnectOKX();
        }
    };

    const handleAccountsChangedOKX = (result: any) => {
        if (self.wallet?.type === "OKX") {
            getBasicInfoOKX(result);
        }
    };
    const connectOKX = async () => {
        try {
            const walletInstalled = checkInstalledOKX();
            if (!walletInstalled) return;
            _window = typeof window === "undefined" ? null : window;
            const walletProvider = (_window as any)?.okxwallet?.bitcoin;
            const result = await walletProvider.connect();
            await getBasicInfoOKX(result);
        } catch (e) {
            throw e;
        }
    };
    const disconnectOKX = async () => {
        setConnected(false);
        setWallet({} as Wallet);
        self.wallet = {} as Wallet;
        setAccounts([]);
        setBalance(0);
        setNetwork(isTestnet ? "Testnet" : "Mainnet");
        self.accounts = [];
        window && _window.localStorage.removeItem("connectedBTCWallets");
    };
    const signMessageOKX = async (message: string) => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = (_window as any)?.okxwallet?.bitcoin;
        const signature = await walletProvider.signMessage(message);
        return signature;
    };
    const signPSBTOKX = async (data: any) => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = (_window as any)?.okxwallet?.bitcoin;

        const inputsToSign = data?.toSignInputs?.map((input: any) => ({
            index: input.index,
            address: input.address,
            publicKey: input.publicKey,
            ...(input?.sighashTypes && { sighashTypes: input.sighashTypes }),
            ...(input?.disableTweakSigner && { disableTweakSigner: input.disableTweakSigner }),
        }));

        const signedPSBTHex = await walletProvider.signPsbt(data.unsignedPSBTHEX, {
            autoFinalized: data?.autoFinalized ? data?.autoFinalized : false,
            ...(data?.toSignInputs && { toSignInputs: inputsToSign }),
        });

        if (data?.autoFinalized && data?.autoFinalized === true) {
            const txId = await walletProvider.pushPsbt(signedPSBTHex);
            return txId;
        } else {
            const signedPSBTBase64 = Buffer.from(signedPSBTHex, "hex").toString("base64");
            return signedPSBTBase64;
        }
    };
    const multiSignPSBTOKX = async (data: any) => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = (_window as any)?.okxwallet?.bitcoin;

        const unsignedPSBTsHEX = data.map((i: any) => i.unsignedPSBTHEX);
        const options = data.map((i: any) => {
            const inputsToSign = i?.toSignInputs?.map((input: any) => ({
                index: input.index,
                address: input.address,
                publicKey: input.publicKey,
                ...(input?.sighashTypes && { sighashTypes: input.sighashTypes }),
                ...(input?.disableTweakSigner && { disableTweakSigner: input.disableTweakSigner }),
            }));
            return {
                autoFinalized: i?.autoFinalized ? i?.autoFinalized : false,
                ...(i?.toSignInputs && { toSignInputs: inputsToSign }),
            };
        });
        const signedPSBTsHex = await walletProvider.signPsbts(unsignedPSBTsHEX, options);

        if (data?.[0]?.autoFinalized && data?.[0]?.autoFinalized === true) {
            let txIds = [];
            for (const signedPSBTHex of signedPSBTsHex) {
                const txId = await walletProvider.pushPsbt(signedPSBTHex);
                await sleep(3000, () => {});
                txIds.push(txId);
            }

            return txIds;
        } else {
            const signedPSBTsBase64 = signedPSBTsHex.map((signedPSBTHex: any) => {
                const signedPSBTBase64 = Buffer.from(signedPSBTHex, "hex").toString("base64");
                return signedPSBTBase64;
            });
            return signedPSBTsBase64;
        }
    };
    const sendInscriptionOKX = async (toAddress: string, inscriptionId: string) => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = (_window as any)?.okxwallet?.bitcoin;
        let transferTxId = await walletProvider.transferNft({
            from: wallet.address,
            to: toAddress,
            data: inscriptionId,
            // type: 26, // 26 => NFT unlist, 55 => BRC20 unlist
        });
        console.log("sendInscriptionOKXTxId:", transferTxId?.txhash);
        return transferTxId?.txhash || "";
    };
    const getInscriptionsOKX = async () => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = (_window as any)?.okxwallet?.bitcoin;
        const inscriptions = await walletProvider.getInscriptions(0, 100);
        return inscriptions?.list || [];
    };
    const getBalanceOKX = async () => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = (_window as any)?.okxwallet?.bitcoin;
        const balance = await walletProvider.getBalance();
        const parseBalance = parseCustomTokenValue(balance?.total, 8);
        setBalance(parseBalance);
        return parseBalance;
    };

    //Liquality
    //https://github.com/liquality/documentation/tree/dev/docs/web_extension/bitcoin
    const checkInstalledLiquality = () => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = (_window as any)?.bitcoin;
        const walletInstalled = walletProvider;
        if (!walletInstalled) {
            setInstalled(false);
            const previouslyConnectedBTCWallets =
                (_window && _window.JSON.parse(window?.localStorage?.getItem("connectedBTCWallets"))) || [];
            !previouslyConnectedBTCWallets?.includes(AddressType.Liquality) && _window.open(okxWalletLink, "_blank");
        } else {
            setInstalled(true);
        }

        return !!walletInstalled;
    };
    const getBasicInfoLiquality = async (result: any) => {
        if (!!result && !isEmpty(result.address)) {
            const account = result[0];
            if (account) {
                const maxAddresses = 20;
                const addressesPerCall = 1;
                let i = 0;

                let allAccounts: any[] = [];
                while (i < maxAddresses) {
                    const externalAccounts = await (window as any).bitcoin.request({
                        method: "wallet_getAddresses",
                        params: [i, addressesPerCall, false],
                    });
                    const changeAccounts = await (window as any).bitcoin.request({
                        method: "wallet_getAddresses",
                        params: [i, addressesPerCall, true],
                    });
                    const _accounts = [...externalAccounts, ...changeAccounts];
                    allAccounts = [...allAccounts, ..._accounts];
                    i += addressesPerCall;
                }

                setWallet({
                    address: account.address,
                    publicKey: account.publicKey,
                    derivationPath: account.derivationPath,
                    type: AddressType.Liquality,
                    icon: "liquality",
                });
                self.wallet = {
                    address: account.address,
                    publicKey: account.publicKey,
                    derivationPath: account.derivationPath,
                    type: AddressType.Liquality,
                    icon: "liquality",
                };

                const allAddresses = allAccounts.map((account: any) => account.address);
                await getBalanceAllProxy(allAddresses);

                setConnected(true);
                setAccounts(allAccounts);

                const connectedBTCWallets = [AddressType.Liquality];

                window && _window.localStorage.setItem("connectedBTCWallets", JSON.stringify(connectedBTCWallets));
            }
        } else {
            disconnectLiquality();
        }
    };
    const connectLiquality = async () => {
        try {
            const walletInstalled = checkInstalledLiquality();
            if (!walletInstalled) return;
            _window = typeof window === "undefined" ? null : window;
            const walletProvider = (_window as any)?.bitcoin;
            const result = await walletProvider?.enable();
            getBasicInfoLiquality(result);
        } catch (e) {}
    };
    const disconnectLiquality = async () => {
        setConnected(false);
        setWallet({} as Wallet);
        self.wallet = {} as Wallet;
        setAccounts([]);
        setNetwork(isTestnet ? "Testnet" : "Mainnet");
        setBalance(0);
        self.accounts = [];
        window && _window.localStorage.removeItem("connectedBTCWallets");
    };
    const signMessageLiquality = async (message: string) => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = (window as any)?.bitcoin;
        await walletProvider?.enable();

        const signature = await walletProvider.request({
            method: "wallet_signMessage",
            params: [message, wallet?.address],
        });
        //String (Hex) - The signature
        return signature;
    };
    const signPSBTLiquality = async (data: any) => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = (window as any)?.bitcoin;
        await walletProvider?.enable();

        const inputsToSign = (data?.toSignInputs || [])?.map((input: any) => ({
            index: input.index,
            derivationPath: input.derivationPath,
        }));
        const signedPSBTBase64 = await walletProvider.request({
            method: "wallet_signPSBT",
            params: [data.unsignedPSBTBase64, inputsToSign],
        });

        if (data?.autoFinalized && data?.autoFinalized === true) {
            const btcTransaction = new TeleswapWallet(sourceNetwork.name);

            const txId = await btcTransaction.sendSignedPsbt(signedPSBTBase64);
            return txId;
        } else {
            return signedPSBTBase64;
        }
    };

    //BOBsnap
    //https://docs.gobob.xyz/docs/build/bob-sdk/metamask-snap
    const checkInstalledSnap = async () => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = _window.ethereum;
        try {
            const result: any = await walletProvider.request({
                method: "wallet_requestSnaps",
                params: {
                    [snapId]: {},
                },
            });

            const walletInstalled = result?.[snapId]?.enabled || false;
            if (!walletInstalled) {
                setInstalled(false);
                const previouslyConnectedBTCWallets =
                    (_window && _window.JSON.parse(window?.localStorage?.getItem("connectedBTCWallets"))) || [];
                !previouslyConnectedBTCWallets?.includes(AddressType.Snap) && _window.open(snapWalletLink, "_blank");
            } else {
                setInstalled(true);
            }
            return walletInstalled;
        } catch (e) {
            return false;
        }
    };
    const getBasicInfoSnap = async (walletType: AddressType) => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = _window.ethereum;
        const scriptType = snapType[walletType.replace(AddressType.Snap + "-", "")].sType;
        const response = await walletProvider.request({
            method: "wallet_invokeSnap",
            params: {
                snapId: snapId,
                request: {
                    method: "btc_getPublicExtendedKey",
                    params: {
                        network: isTestnet ? "test" : "main",
                        scriptType: scriptType,
                    },
                },
            },
        });

        const xpubAccount = getPublicKeyFromXpub(
            response.xpub,
            snapType[walletType.replace(AddressType.Snap + "-", "")].type,
        );

        const account = {
            address: xpubAccount.address,
            publicKey: xpubAccount.publicKey,
            masterFingerprint: response.mfp,
            derivationPath: isTestnet
                ? snapType[walletType.replace(AddressType.Snap + "-", "")].derivationPathTestnet
                : snapType[walletType.replace(AddressType.Snap + "-", "")].derivationPathMainnet,
        };

        setWallet({
            address: account.address,
            publicKey: account.publicKey,

            masterFingerprint: response.mfp,
            derivationPath: isTestnet
                ? snapType[walletType.replace(AddressType.Snap + "-", "")].derivationPathTestnet
                : snapType[walletType.replace(AddressType.Snap + "-", "")].derivationPathMainnet,
            type: walletType,
            icon: "metamask",
        });
        self.wallet = {
            address: account.address,
            publicKey: account.publicKey,

            masterFingerprint: response.mfp,
            derivationPath: isTestnet
                ? snapType[walletType.replace(AddressType.Snap + "-", "")].derivationPathTestnet
                : snapType[walletType.replace(AddressType.Snap + "-", "")].derivationPathMainnet,
            type: walletType,
            icon: "metamask",
        };

        const allAddresses = [account].map((account: any) => account.address);
        await getBalanceAllProxy(allAddresses);

        setConnected(true);
        setAccounts([account]);

        const connectedBTCWallets = [walletType];

        window && _window.localStorage.setItem("connectedBTCWallets", JSON.stringify(connectedBTCWallets));
    };
    const connectSnap = async (walletType: AddressType) => {
        try {
            _window = typeof window === "undefined" ? null : window;
            const walletProvider = _window.ethereum;
            const walletInstalled = await checkInstalledSnap();
            if (!walletInstalled) return;

            await getBasicInfoSnap(walletType);
        } catch (e) {
            throw e;
        }
    };
    const disconnectSnap = async () => {
        setConnected(false);
        setWallet({} as Wallet);
        self.wallet = {} as Wallet;
        setAccounts([]);
        setNetwork(isTestnet ? "Testnet" : "Mainnet");
        setBalance(0);
        self.accounts = [];
        window && _window.localStorage.removeItem("connectedBTCWallets");
    };
    //TODO
    const signMessageSnap = async (message: string) => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = _window.ethereum;

        throw new Error(`Sign Message for Snap not supported`);
    };
    const signPSBTSnap = async (data: any) => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = _window.ethereum;
        const addressType = data.signerInfo.addressType;
        const scriptType = snapType[addressType].sType;
        const result: { txId: string; txHex: string } = await walletProvider.request({
            method: "wallet_invokeSnap",
            params: {
                snapId: snapId,
                request: {
                    method: "btc_signPsbt",
                    params: {
                        psbt: data.unsignedPSBTBase64, // base64 string for the pbst,
                        network: isTestnet ? "test" : "main", // for testnet use "test",
                        scriptType: scriptType, // "P2SH-P2WPKH" or "P2WPKH"
                    },
                },
            },
        });

        if (data?.autoFinalized && data?.autoFinalized === true) {
            const sendTxResponse: any = await request.post(`${baseAPI}/utils/bitcoin/send-tx`, {
                testnet: isTestnet,
                txHex: result.txHex,
            });
            const txId = sendTxResponse?.data?.txId;
            return txId;
        } else {
            const signedPSBTBase64 = Buffer.from(result.txHex, "hex").toString("base64");
            return signedPSBTBase64;
        }
    };

    //Phantom
    //https://docs.phantom.app/bitcoin/integrating-phantom
    const checkInstalledPhantom = async () => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = (_window as any)?.phantom?.bitcoin;
        const walletInstalled = walletProvider?.isPhantom;
        if (!walletInstalled) {
            setInstalled(false);
            const previouslyConnectedBTCWallets =
                (_window && _window.JSON.parse(window?.localStorage?.getItem("connectedBTCWallets"))) || [];
            !previouslyConnectedBTCWallets?.includes(AddressType.Phantom) && _window.open(phantomWalletLink, "_blank");
        } else {
            setInstalled(true);
        }

        return !!walletInstalled;
    };
    const getBasicInfoPhantom = async (result: any, walletType: AddressType) => {
        if (!!result && result?.length > 0) {
            const accounts = result;
            const account = accounts.find(
                (_account: any) => _account.addressType === walletType.replace(AddressType.Phantom + "-", ""),
            );
            if (accounts && accounts.length > 0 && account) {
                setWallet({
                    address: account.address,
                    publicKey: account.publicKey,
                    type: walletType,
                    icon: "phantom",
                });
                self.wallet = {
                    address: account.address,
                    publicKey: account.publicKey,
                    type: walletType,
                    icon: "phantom",
                };

                setConnected(true);
                setAccounts([account]);

                getBalanceProxy(account.address);

                const connectedBTCWallets = [walletType];
                // (window &&
                //     _window.JSON.parse(window?.localStorage?.getItem('connectedBTCWallets'))) ||

                window && _window.localStorage.setItem("connectedBTCWallets", JSON.stringify(connectedBTCWallets));
            } else {
                throw new Error(
                    `Choose ${walletTypeToTitle(walletType)} as your preferred Bitcoin address in your wallet..`,
                );
            }
        } else {
            disconnectPhantom();
        }
    };
    const connectPhantom = async (walletType: AddressType) => {
        try {
            const walletInstalled = checkInstalledPhantom();
            if (!walletInstalled) return;
            _window = typeof window === "undefined" ? null : window;
            const walletProvider = (_window as any)?.phantom?.bitcoin;
            const result = await walletProvider.requestAccounts();

            await getBasicInfoPhantom(result, walletType);
        } catch (e) {
            throw e;
        }
    };
    const disconnectPhantom = async () => {
        setConnected(false);
        setWallet({} as Wallet);
        self.wallet = {} as Wallet;
        self.accounts = [];
        setAccounts([]);
        setNetwork(isTestnet ? "Testnet" : "Mainnet");
        setBalance(0);
        self.accounts = [];

        window && _window.localStorage.removeItem("connectedBTCWallets");
    };
    const signMessagePhantom = async (_message: string) => {
        const message = new TextEncoder().encode(_message);
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = (_window as any)?.phantom?.bitcoin;
        const { signature } = await walletProvider.signMessage(wallet?.address, message);
        const signatureBytes = signature;
        const signatureBase64 = bytesToBase64(signatureBytes);
        return signatureBase64;
    };
    const signPSBTPhantom = async (data: any) => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = (_window as any)?.phantom?.bitcoin;

        const inputsToSign = data?.toSignInputs?.map((input: any) => ({
            signingIndexes: [input.index],
            address: input.address,
            ...(input?.sighashTypes && { sigHash: input.sighashTypes[0] }),
        }));

        const signedPSBTBytes = await walletProvider.signPSBT(fromHexString(data.unsignedPSBTHEX), {
            ...(data?.toSignInputs && { inputsToSign: inputsToSign }),
        });
        const signedPSBTBase64 = bytesToBase64(signedPSBTBytes);
        if (data?.autoFinalized && data?.autoFinalized === true) {
            const btcTransaction = new TeleswapWallet(sourceNetwork.name);

            const txId = await btcTransaction.sendSignedPsbt(signedPSBTBase64);
            return txId;
        } else {
            return signedPSBTBase64;
        }
    };

    //Leather
    //https://leather.io/developer-docs
    const checkInstalledLeather = async () => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = (_window as any)?.LeatherProvider;
        const walletInstalled = walletProvider?.isLeather;
        if (!walletInstalled) {
            setInstalled(false);
            const previouslyConnectedBTCWallets =
                (_window && _window.JSON.parse(window?.localStorage?.getItem("connectedBTCWallets"))) || [];
            !previouslyConnectedBTCWallets?.includes(AddressType.Leather) && _window.open(leatherWalletLink, "_blank");
        } else {
            setInstalled(true);
        }

        return !!walletInstalled;
    };
    const getBasicInfoLeather = async (result: any, walletType: AddressType) => {
        if (!!result && result?.result?.addresses?.length > 0) {
            const accounts = result?.result?.addresses;

            const account = accounts.find(
                (_account: any) => _account.type === walletType?.replace(AddressType.Leather + "-", ""),
            );
            if (accounts && accounts.length > 0 && account) {
                setWallet({
                    address: account.address,
                    publicKey: account.publicKey,
                    derivationPath: account.derivationPath,
                    type: walletType,
                    icon: "leather",
                });
                self.wallet = {
                    address: account.address,
                    publicKey: account.publicKey,
                    derivationPath: account.derivationPath,
                    type: walletType,
                    icon: "leather",
                };

                await getBalanceProxy(account.address);

                setConnected(true);
                setAccounts([account]);

                const connectedBTCWallets = [walletType];

                window && _window.localStorage.setItem("connectedBTCWallets", JSON.stringify(connectedBTCWallets));
            } else {
                throw new Error(
                    `Choose ${walletTypeToTitle(walletType)} as your preferred Bitcoin address in your wallet.. `,
                );
            }
        } else {
            disconnectLeather();
        }
    };
    const connectLeather = async (walletType: AddressType) => {
        try {
            const walletInstalled = checkInstalledLeather();
            if (!walletInstalled) return;
            _window = typeof window === "undefined" ? null : window;
            const walletProvider = (_window as any)?.LeatherProvider;
            const result = await walletProvider.request("getAddresses");
            await getBasicInfoLeather(result, walletType);
        } catch (e) {
            throw e;
        }
    };
    const disconnectLeather = async () => {
        setConnected(false);
        setWallet({} as Wallet);
        self.wallet = {} as Wallet;
        setAccounts([]);
        setBalance(0);
        setNetwork(isTestnet ? "Testnet" : "Mainnet");
        self.accounts = [];
        window && _window.localStorage.removeItem("connectedBTCWallets");
    };
    const signMessageLeather = async (message: string) => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = (_window as any)?.LeatherProvider;
        const signature = await walletProvider.request("signMessage", {
            message,
            paymentType: wallet.type?.replace(AddressType.Leather + "-", ""), // or 'p2wphk' (default)
            network: isTestnet ? "testnet" : "mainnet",
        });
        return signature;
    };
    const signPSBTLeather = async (data: any) => {
        _window = typeof window === "undefined" ? null : window;
        const walletProvider = (_window as any)?.LeatherProvider;

        const inputsToSign = data?.toSignInputs?.map((input: any) => ({
            signingIndexes: [input.index],
            address: input.address,
            ...(input?.sighashTypes && { sigHash: input.sighashTypes[0] }),
        }));

        const signAtIndex = data?.toSignInputs?.map((input: any) => input.index);
        const sighashTypes = inputsToSign?.map((input: any) => input?.sigHash);

        const requestParams: SignPsbtRequestParamsLeatherWallet = {
            hex: data.unsignedPSBTHEX,
            // broadcast: data?.autoFinalized ? data?.autoFinalized : false, //TODO cant get txId
            ...(data?.toSignInputs && { signAtIndex }),
            ...(data?.sighashTypes && { allowedSighash: [sighashTypes[0]] }),
        };

        const signedPSBTHexOrTxIdResult = await walletProvider.request("signPsbt", requestParams);

        if (data?.autoFinalized && data?.autoFinalized === true) {
            const signedPSBTBase64 = Buffer.from(signedPSBTHexOrTxIdResult?.result.hex, "hex").toString("base64");
            const btcTransaction = new TeleswapWallet(sourceNetwork.name);

            const txId = await btcTransaction.sendSignedPsbt(signedPSBTBase64);
            return txId;
            // return signedPSBTHexOrTxIdResult?.result.txId; //TODO cant get txId
        } else {
            const signedPSBTBase64 = Buffer.from(signedPSBTHexOrTxIdResult?.result.hex, "hex").toString("base64");
            return signedPSBTBase64;
        }
    };

    //Ledger
    const signPSBTLedger = async (data: any) => {
        const signedPSBTBase64 = await ledgerBitcoinApp.signPsbtV0(data.unsignedPSBTBase64);
        if (data?.autoFinalized && data?.autoFinalized === true) {
            const btcTransaction = new TeleswapWallet(sourceNetwork.name);

            const txId = await btcTransaction.sendSignedPsbt(signedPSBTBase64);
            return txId;
        } else {
            return signedPSBTBase64;
        }
    };
    //Trezor
    const connectTrezor = async (walletType: any) => {
        const trezorBitcoin = instantApp ? instantApp : null;
        // new TrezorBitcoin(isTestnet, walletType?.replace("Trezor-", ""));
        try {
            try {
                !trezorBitcoin?.accountInitialized && (await trezorBitcoin.init());
            } catch (err: any) {
                console.log("retry init");
                throw new Error(`not supported`);
            }

            if (
                !trezorBitcoin?.accountInitialized ||
                walletType?.replace("Trezor-", "") !== trezorBitcoin.addressType
            ) {
                await trezorBitcoin.initAccountInfo(walletType?.replace("Trezor-", ""));

                const _accounts = await trezorBitcoin.getDefaultAccounts();

                const account = _accounts.find(
                    (_account: any) => _account.addressType === walletType?.replace(AddressType.Trezor + "-", ""),
                );
                if (_accounts && _accounts.length > 0 && account) {
                    setWallet({
                        address: account.address,
                        publicKey: account.publicKey,
                        derivationPath: account.derivationPath,
                        type: walletType,
                        icon: "trezor",
                    });
                    self.wallet = {
                        address: account.address,
                        publicKey: account.publicKey,
                        derivationPath: account.derivationPath,
                        type: walletType,
                        icon: "trezor",
                    };

                    await getBalanceProxy(account.address);

                    setConnected(true);
                    setAccounts(_accounts);
                    setInstantApp(trezorBitcoin);

                    const connectedBTCWallets = [walletType];

                    window && _window.localStorage.setItem("connectedBTCWallets", JSON.stringify(connectedBTCWallets));
                } else {
                    throw new Error(
                        `Choose ${walletTypeToTitle(walletType)} as your preferred Bitcoin address in your wallet..`,
                    );
                }
            }
        } catch (err: any) {
            // await trezorBitcoin.disconnect();
            setAccounts([]);
            setInstantApp(null);

            if (err?.message?.includes("trezor device: UNKNOWN_ERROR (0x6a82)") || err?.message?.includes("(0x6e01)")) {
                if (sourceNetwork.name === "bitcoin_testnet") {
                    throw new Error(`Please Connect Bitcoin Testnet Network`);
                } else if (sourceNetwork.name === "bitcoin") {
                    throw new Error(`Please Connect Bitcoin Mainnet Network`);
                } else {
                    throw new Error(err);
                }
            } else if (err?.message?.includes("0x5515)")) {
                throw new Error("trezor is Locked");
            } else {
                throw new Error(err);
            }
        }
    };
    const signPSBTTrezor = async (data: any) => {
        const signedPSBTBase64 = await trezorBitcoinApp.createAndSignTx(data.utx);
        if (data?.autoFinalized && data?.autoFinalized === true) {
            const btcTransaction = new TeleswapWallet(sourceNetwork.name);

            const txId = await btcTransaction.sendSignedPsbt(signedPSBTBase64);
            return txId;
        } else {
            return signedPSBTBase64;
        }
    };

    //Global
    const connect = async (walletType: AddressType) => {
        if (walletType === "Unisat") {
            await connectUnisat();
        } else if (walletType.includes("XVerse")) {
            await connectXVerse(walletType);
        } else if (walletType.includes("MagicEden")) {
            await connectMagicEden(walletType);
        } else if (walletType === "OKX") {
            await connectOKX();
        } else if (walletType.includes("XDefi")) {
            await connectXDefi(walletType);
        } else if (walletType === "Liquality") {
            await connectLiquality();
        } else if (walletType.includes("Snap")) {
            await connectSnap(walletType);
        } else if (walletType.includes("Phantom")) {
            await connectPhantom(walletType);
        } else if (walletType.includes("Leather")) {
            await connectLeather(walletType);
        } else if (walletType.includes("Trezor")) {
            await connectTrezor(walletType);
        }

        return self.wallet;
    };
    const disconnect = async () => {
        if (wallet?.type === "Unisat") {
            disconnectUnisat();
        } else if (wallet?.type.includes("XVerse")) {
            disconnectXVerse();
        } else if (wallet?.type.includes("MagicEden")) {
            disconnectMagicEden();
        } else if (wallet?.type === "OKX") {
            disconnectOKX();
        } else if (wallet?.type.includes("XDefi")) {
            disconnectXDefi();
        } else if (wallet?.type === "Liquality") {
            disconnectLiquality();
        } else if (wallet?.type.includes("Snap")) {
            disconnectSnap();
        } else if (wallet?.type.includes("Phantom")) {
            disconnectPhantom();
        } else if (wallet?.type.includes("Leather")) {
            disconnectLeather();
        }
    };
    const switchNetwork = async () => {
        _window = typeof window === "undefined" ? null : window;

        if (wallet?.type === "Unisat") {
            const walletProvider = (_window as any)?.unisat;
            await walletProvider.switchNetwork(network === "Testnet" ? "livenet" : "testnet");
            const newNetwork = await walletProvider.getNetwork();
            setNetwork(newNetwork === "livenet" ? "Mainnet" : "Testnet");
        } else {
            throw new Error(`Switch Network for ${wallet?.type} not supported`);
        }
    };
    const signMessageReq = async (message: string) => {
        if (wallet?.type === "Unisat") {
            return await signMessageUnisat(message);
        } else if (wallet?.type.includes("XVerse")) {
            return await signMessageXVerse(message);
        } else if (wallet?.type.includes("MagicEden")) {
            return await signMessageMagicEden(message);
        } else if (wallet?.type === "OKX") {
            return await signMessageOKX(message);
        } else if (wallet?.type.includes("XDefi")) {
            return await signMessageXDefi(message);
        } else if (wallet?.type === "Liquality") {
            return await signMessageLiquality(message);
        } else if (wallet?.type.includes("Snap")) {
            return await signMessageSnap(message);
        } else if (wallet?.type === "Phantom") {
            return await signMessagePhantom(message);
        } else if (wallet?.type.includes("Leather")) {
            return await signMessageLeather(message);
        }
    };
    const signPSBTByType = async (data: any, type: any) => {
        if (type.includes("unisat")) {
            return await signPSBTUnisat(data);
        } else if (type.includes("xverse")) {
            return await signPSBTXVerse(data);
        } else if (type.includes("magiceden")) {
            return await signPSBTMagicEden(data);
        } else if (type.includes("okx")) {
            return await signPSBTOKX(data);
        } else if (type.includes("xdefi")) {
            return await signPSBTXDefi(data);
        } else if (type.includes("phantom")) {
            return await signPSBTPhantom(data);
        } else if (type.includes("liquality")) {
            return await signPSBTLiquality(data);
        } else if (type.includes("snap")) {
            return await signPSBTSnap(data);
        } else if (type.includes("ledger")) {
            return await signPSBTLedger(data);
        } else if (type.includes("trezor")) {
            return await signPSBTTrezor(data);
        } else if (type.includes("leather")) {
            return await signPSBTLeather(data);
        }
    };
    const multiSignPSBTByType = async (data: any, type: any) => {
        if (type.includes("unisat")) {
            return await multiSignPSBTUnisat(data);
        } else if (type.includes("xverse")) {
            return await multiSignPSBTXVerse(data);
        } else if (type.includes("magiceden")) {
            return await multiSignPSBTMagicEden(data);
        } else if (type.includes("okx")) {
            return await multiSignPSBTOKX(data);
        } else {
            throw new Error(`MultiSign PSBT for ${type} not supported`);
        }
    };
    const signPSBT = async (data: any) => {
        if (wallet?.type === "Unisat") {
            return await signPSBTUnisat(data);
        } else if (wallet?.type.includes("XVerse")) {
            return await signPSBTXVerse(data);
        } else if (wallet?.type.includes("MagicEden")) {
            return await signPSBTMagicEden(data);
        } else if (wallet?.type === "OKX") {
            return await signPSBTOKX(data);
        } else if (wallet?.type.includes("XDefi")) {
            return await signPSBTXDefi(data);
        } else if (wallet?.type.includes("Phantom")) {
            return await signPSBTPhantom(data);
        } else if (wallet?.type === "Liquality") {
            return await signPSBTLiquality(data);
        } else if (wallet?.type.includes("Snap")) {
            return await signPSBTSnap(data);
        } else if (wallet?.type === "Ledger") {
            return await signPSBTLedger(data);
        } else if (wallet?.type === "Trezor") {
            return await signPSBTTrezor(data);
        } else if (wallet?.type.includes("Leather")) {
            return await signPSBTLeather(data);
        }
    };
    const multiSignPSBT = async (data: any) => {
        if (wallet?.type === "Unisat") {
            return await multiSignPSBTUnisat(data);
        } else if (wallet?.type.includes("XVerse")) {
            return await multiSignPSBTXVerse(data);
        } else if (wallet?.type.includes("MagicEden")) {
            return await multiSignPSBTMagicEden(data);
        } else if (wallet?.type === "OKX") {
            return await multiSignPSBTOKX(data);
        } else {
            throw new Error(`MultiSign PSBT for ${wallet?.type} not supported`);
        }
    };

    const sendInscription = async (toAddress: string, inscriptionId: string, location: string) => {
        if (wallet?.type === "Unisat") {
            return await sendInscriptionUnisat(toAddress, inscriptionId);
        } else if (wallet?.type.includes("XVerse")) {
            return await sendInscriptionWithSignPSBT(toAddress, inscriptionId, location);
        } else if (wallet?.type.includes("MagicEden")) {
            return await sendInscriptionWithSignPSBT(toAddress, inscriptionId, location);
        } else if (wallet?.type === "OKX") {
            return await sendInscriptionOKX(toAddress, inscriptionId);
        } else if (wallet?.type.includes("XDefi")) {
            return await sendInscriptionWithSignPSBT(toAddress, inscriptionId, location);
        } else if (wallet?.type === "Liquality") {
            return await sendInscriptionWithSignPSBT(toAddress, inscriptionId, location);
        } else if (wallet?.type.includes("Snap")) {
            return await sendInscriptionWithSignPSBT(toAddress, inscriptionId, location);
        } else if (wallet?.type.includes("Phantom")) {
            return await sendInscriptionWithSignPSBT(toAddress, inscriptionId, location);
        } else if (wallet?.type.includes("Leather")) {
            return await sendInscriptionWithSignPSBT(toAddress, inscriptionId, location);
        }
    };
    const getInscriptions = async () => {
        if (wallet?.type === "Unisat") {
            return await getInscriptionsUnisat();
        } else if (wallet?.type.includes("XVerse")) {
            return await getInscriptionsFake();
        } else if (wallet?.type.includes("MagicEden")) {
            return await getInscriptionsFake();
        } else if (wallet?.type === "OKX") {
            return await getInscriptionsOKX();
        } else if (wallet?.type.includes("XDefi")) {
            return await getInscriptionsFake();
        } else if (wallet?.type === "Liquality") {
            return await getInscriptionsFake();
        } else if (wallet?.type.includes("Snap")) {
            return await getInscriptionsFake();
        } else if (wallet?.type === "Ledger") {
            return await getInscriptionsFake();
        } else if (wallet?.type === "Trezor") {
            return await getInscriptionsFake();
        } else if (wallet?.type.includes("Phantom")) {
            return await getInscriptionsFake();
        } else if (wallet?.type.includes("Leather")) {
            return await getInscriptionsFake();
        }
    };
    const getBalance = async () => {
        if (wallet.address) {
            if (wallet?.type === "Unisat") {
                return await getBalanceProxy(wallet.address);
            } else if (wallet?.type.includes("XVerse")) {
                return await getBalanceProxy(wallet.address);
            } else if (wallet?.type.includes("MagicEden")) {
                return await getBalanceProxy(wallet.address);
            } else if (wallet?.type === "OKX") {
                return await getBalanceOKX();
            } else if (wallet?.type.includes("XDefi")) {
                return await getBalanceProxy(wallet.address);
            } else if (wallet?.type === "Liquality") {
                const allAddresses = accounts.map((account: any) => account.address);
                return await getBalanceAllProxy(allAddresses);
            } else if (wallet?.type.includes("Snap")) {
                const allAddresses = accounts.map((account: any) => account.address);
                return await getBalanceAllProxy(allAddresses);
            } else if (wallet?.type.includes("Phantom")) {
                const allAddresses = accounts.map((account: any) => account.address);
                return await getBalanceAllProxy(allAddresses);
            } else if (wallet?.type.includes("Leather")) {
                return await getBalanceProxy(wallet.address);
            }
        } else {
            return 0;
        }
    };
    useEffect(() => {
        unisat && unisat.on("accountsChanged", handleAccountsChangedUnisat);
        unisat && unisat.on("networkChanged", handleNetworkChangedUnisat);

        okxWallet && okxWallet?.on("accountChanged", handleAccountsChangedOKX);

        // phantomWallet && phantomWallet.on("accountsChanged", getBasicInfoPhantom);

        // const previouslyConnectedBTCWallets =
        //     (_window && _window.JSON.parse(window?.localStorage?.getItem("connectedBTCWallets"))) || [];
        // (async () => {
        //     try {
        //         if (previouslyConnectedBTCWallets?.length > 0) {
        //             if (previouslyConnectedBTCWallets.includes(AddressType.Unisat)) {
        //                 connect(AddressType.Unisat);
        //             } else if (previouslyConnectedBTCWallets.includes(AddressType.OKX)) {
        //                 connect(AddressType.OKX);
        //             }
        //         }
        //     } catch (error) {
        //         console.log(error);
        //     }
        // })();

        return () => {
            unisat && unisat.removeListener("accountsChanged", handleAccountsChangedUnisat);
            unisat && unisat.removeListener("networkChanged", handleNetworkChangedUnisat);

            okxWallet && okxWallet.removeListener("accountChanged", handleAccountsChangedOKX);

            // phantomWallet && phantomWallet.removeListener("accountsChanged", getBasicInfoPhantom);
        };
    }, []);

    const { data: btcUSDPrice = 0, isFetching: btcUSDPriceLoading } = useQuery({
        queryKey: [`btcUSDPrice`],
        queryFn: () => {
            return getTokenPrice("BTC");
        },
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

    const handleSetWallet = (wallet: Wallet) => {
        setWallet(wallet);
        self.wallet = wallet;
        const connectedBTCWallets = [wallet.type];

        window && _window.localStorage.setItem("connectedBTCWallets", JSON.stringify(connectedBTCWallets));
    };

    const contextValues = useMemo((): IBitcoinWalletContext => {
        return {
            installed,
            connected,
            wallet,
            network,
            balance,
            accounts,
            setWallet: handleSetWallet,
            connect,
            disconnect,
            switchNetwork,
            signMessage: signMessageReq,
            sendInscription,
            signPSBT,
            multiSignPSBT,
            signPSBTByType,
            multiSignPSBTByType,
            getBalance,
            getInscriptions,
            btcUSDPrice: btcUSDPrice,
        };
    }, [connect, disconnect, installed, connected, wallet, network, btcUSDPrice]);

    return <BitcoinWalletContext.Provider value={contextValues}>{children}</BitcoinWalletContext.Provider>;
};

const useBitcoinWallet = () => {
    const context = useContext(BitcoinWalletContext);
    if (context === undefined) {
        throw new Error("useBitcoinWallet must be used within a BitcoinWalletProvider");
    }
    return context;
};

export { BitcoinWalletContext, BitcoinWalletProvider, useBitcoinWallet };
