/* eslint-disable @typescript-eslint/no-unused-vars */

import classnames from "classnames";
import "./ToastWrapper.scss";
import CloseIcon from "assets/svgs/close.svg";

import Success from "assets/svgs/success.svg";
import Thumbs from "assets/svgs/thumbs-up.svg";
import Image from "ui-components/Image";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import Button from "ui-components/Button";
import TransactionAddress from "components/TransactionAddress";
import Loading from "ui-components/Loading";
import useToast from "utils/useToast";
import { useNavigate } from "react-router-dom";
import CountdownTimer from "components/Timer";
import ActionShare from "components/ActionShare";
import { useQuery } from "@tanstack/react-query";
import { useTeleSwap } from "services/useTeleSwap";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { trackEvent } from "utils/analytics";
export type ToastWrapperType = "success" | "error" | "warning";

interface ToastWrapperProps {
    key: string | number;
    onClose: () => void;
    className?: string;
    type: any;
    content: any;
}
const ToastWrapper = ({ onClose, type, content }: ToastWrapperProps) => {
    const isClosable = content?.closeText ? true : content?.closable;
    const toast = useToast();
    const [feedbackDone, setFeedbackDone] = useState(false);
    const navigate = useNavigate();
    const handleClose = () => {
        if (isClosable || content?.onAction) {
            content?.onClose && content?.onClose();
            onClose && onClose();
        }
    };

    const haveExperience =
        (type === "success" &&
            (content?.topTitle?.includes("submitted") ||
                content?.topTitle?.includes("successfully") ||
                content?.showIcon)) ||
        content?.haveExperience;
    const handleFeedback = (item: any) => {
        trackEvent({
            category: "request",
            action: "feedback",
            properties: [{ key: item.key, details: content?.tokenDetails || content?.token }],
        });
        setFeedbackDone(true);
    };

    const { getEmailAndQuestions } = useTeleSwap();

    const { data: survey, isLoading: surveyIsLoading } = useQuery({
        queryKey: [`survey`],
        queryFn: () => {
            return getEmailAndQuestions();
        },
        refetchInterval: false,
        enabled: !!content?.remaining,
        refetchOnWindowFocus: false,
        retry: false,
    });

    return (
        <Transition show={true} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={handleClose}>
                <div className="fixed inset-0 backdrop-blur-custom bg-secondary/70" aria-hidden="true" />
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center w-full">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className={classnames(
                                    "w-full max-w-lg transform overflow-hidden rounded-2xl  p-6 text-left align-middle shadow-xl transition-all",
                                    !content?.actionContent ? "bg-ground-lighter" : "bg-ground-lighter",
                                )}
                            >
                                {type === "success" && (
                                    <div className=" flex justify-center mt-10">
                                        {content?.haveLoading ? (
                                            <Loading />
                                        ) : (
                                            (content?.topTitle?.includes("submitted") ||
                                                content?.topTitle?.includes("successfully") ||
                                                content?.showIcon) && (
                                                <>
                                                    <img width={77} height={77} src={Success} />
                                                </>
                                            )
                                        )}
                                    </div>
                                )}
                                <Dialog.Title
                                    as="h3"
                                    className={classnames(
                                        "text-lg font-bold py-3 leading-6  text-center",
                                        !content?.actionContent ? "text-primary" : "text-primary ",
                                    )}
                                >
                                    {content?.topTitle ? content?.topTitle : `Transaction ${type}`}
                                </Dialog.Title>
                                {content?.remaining && (
                                    <div className="text-gray-light flex justify-center gap-2">
                                        Remaining time to process your request is
                                        <span className="text-gray-light">
                                            <CountdownTimer
                                                date={
                                                    new Date(
                                                        new Date().getTime() +
                                                            new Date(content?.remaining * 60 * 1000).getTime(),
                                                    )
                                                }
                                            />
                                        </span>
                                    </div>
                                )}
                                {content?.title && (
                                    <div
                                        className={classnames(
                                            " pt-1 pb-2 leading-6 flex justify-center text-center",
                                            !content?.actionContent ? "text-gray-lighter" : "text-gray-lighter",
                                        )}
                                    >
                                        {content?.title}
                                    </div>
                                )}
                                {content?.message && (
                                    <div
                                        className={classnames(
                                            " space break-space pt-1 pb-3 leading-6 flex justify-center  text-center",
                                            !content?.actionContent ? "text-gray-lighter" : "text-gray-lighter",
                                        )}
                                    >
                                        {content?.message}
                                    </div>
                                )}
                                {content?.error && (
                                    <div className="text-center text-red-400 px-4 text-base">{content?.error}</div>
                                )}
                                {content?.tx && (
                                    <div
                                        className={classnames(
                                            " space break-space pt-1 pb-3 leading-6 flex justify-center  text-center",
                                            !content?.actionContent ? "text-primary" : "text-primary ",
                                        )}
                                    >
                                        <TransactionAddress {...content?.tx} />
                                    </div>
                                )}
                                {/* {content?.remaining && !survey && !surveyIsLoading && (
                                    <ActionShare
                                        onClose={() => {
                                            toast.clearAll();
                                            navigate(`/transaction-history`);
                                        }}
                                    />
                                )} */}
                                {content?.token && (
                                    <div className="flex pt-4 pb-4 gap-8 items-center relative justify-center  mx-auto">
                                        <div className="flex items-center gap-2">
                                            <div className="flex relative">
                                                {content?.token?.inputToken && (
                                                    <Image
                                                        className="cursor-pointer  mr-1 rounded-full -ml-2"
                                                        title={content?.token?.inputToken?.symbol}
                                                        src={`https://statics.teleportdao.xyz/coin/${content?.token?.inputToken?.symbol?.toUpperCase()}.png`}
                                                        width={"32"}
                                                        height="32"
                                                        alt="switch"
                                                    />
                                                )}
                                                {content?.token?.inputToken?.network && (
                                                    <Image
                                                        className="cursor-pointer absolute right-[1px] bottom-0 rounded-full"
                                                        title={content?.token?.inputToken?.network}
                                                        src={`/images/colorful/${content?.token?.inputToken?.network?.toLowerCase()}.png`}
                                                        style={{
                                                            width: 13,
                                                            height: 13,
                                                        }}
                                                        alt="parent"
                                                    />
                                                )}
                                            </div>
                                            {content?.token?.inputAmount && (
                                                <div className="text-white text-sm">
                                                    {content?.token?.inputAmount} {content?.token?.inputToken?.symbol}
                                                </div>
                                            )}
                                        </div>
                                        {content?.token?.outputToken && (
                                            <ArrowRightIcon fill="white" className={` h-5 w-5 `} />
                                        )}
                                        {content?.token?.outputToken && (
                                            <div className="flex items-center gap-2">
                                                <div className="flex relative">
                                                    {content?.token?.outputToken && (
                                                        <Image
                                                            className="cursor-pointer  mr-1 rounded-full -ml-2"
                                                            title={content?.token?.outputToken?.symbol}
                                                            src={`https://statics.teleportdao.xyz/coin/${content?.token?.outputToken?.symbol?.toUpperCase()}.png`}
                                                            width={"32"}
                                                            height="32"
                                                            alt="switch"
                                                        />
                                                    )}
                                                    {content?.token?.outputToken?.network && (
                                                        <Image
                                                            className="cursor-pointer absolute right-0 bottom-0 rounded-full"
                                                            title={content?.token?.outputToken?.network}
                                                            src={`/images/colorful/${content?.token?.outputToken?.network?.toLowerCase()}.png`}
                                                            style={{
                                                                width: 13,
                                                                height: 13,
                                                            }}
                                                            alt="parent"
                                                        />
                                                    )}
                                                </div>
                                                <div className="text-white text-sm">
                                                    {content?.token?.outputAmount} {content?.token?.outputToken?.symbol}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}{" "}
                                {haveExperience && !feedbackDone && (
                                    <div className="flex flex-col  m-auto p-3 justify-center items-center mt-2 border bg-ground rounded-lg w-fit border-ground_border-light">
                                        <span className="text-white text-sm">How was your experience?</span>
                                        <div className="flex flex-wrap flex-row w-fit  mx-auto gap-2 mt-2 rounded-lg   p-3 ">
                                            {[
                                                { key: 1, value: "😔" },
                                                { key: 2, value: "🙁" },
                                                { key: 3, value: "😐" },
                                                { key: 4, value: "🙂" },
                                                { key: 5, value: "🤩" },
                                            ].map((item, index) => (
                                                <div
                                                    key={item.key + index}
                                                    className="flex items-center hover:cursor-pointer hover:bg-ground_border-lighter gap-2  rounded-lg text-center justify-center px-5 py-2 border border-gray-600"
                                                    onClick={() => handleFeedback(item)}
                                                >
                                                    <span>{item.value}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {haveExperience && feedbackDone && (
                                    <div className="flex flex-row gap-2 m-auto p-6 justify-center items-center mt-2 border bg-ground rounded-lg w-full max-w-[320px] border-ground_border-light">
                                        <span>
                                            <img width={37} height={37} src={Thumbs} />
                                        </span>
                                        <span className="text-white text-sm">Thank you for your feedback!</span>
                                    </div>
                                )}
                                {content?.onAction && (
                                    <div className="flex mt-4 w-full justify-center">
                                        {content?.actionContent !== "View transaction" ? (
                                            <div
                                                className={
                                                    isClosable && !content?.onAction ? "px-2 w-1/2" : "px-2 w-full "
                                                }
                                            >
                                                <Button
                                                    className="bg-core border-core border-2 hover:bg-transparent text-black hover:text-core"
                                                    onClick={content?.onAction}
                                                >
                                                    {content?.actionContent}
                                                </Button>
                                            </div>
                                        ) : (
                                            <div className={"px-2 w-full  flex justify-center cursor-pointer"}>
                                                <span
                                                    className=" text-white hover:cursor-pointer underline underline-offset-8 decoration-white hover:decoration-core hover:text-core"
                                                    onClick={content?.onAction}
                                                >
                                                    {content?.actionContent}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {(isClosable || content?.onAction) && (
                                    <button role="button" className="ui-toast-close-icon" onClick={handleClose}>
                                        <img src={CloseIcon} height="30" width="30" />
                                    </button>
                                )}
                                {content?.topTitle?.includes("submitted") &&
                                    !content?.actionContent?.includes("View transaction") &&
                                    !content?.title?.includes("LP token") &&
                                    !content?.title?.includes("TST") &&
                                    !content?.topTitle?.includes("LP token") &&
                                    !content?.topTitle?.includes("add liquidity") &&
                                    !content?.topTitle?.includes("liquidity") &&
                                    !content?.title?.includes("Exchange was successful") && (
                                        <div className="flex mt-4 w-full justify-center">
                                            <div className={"px-2 flex justify-center w-full"}>
                                                <span
                                                    className=" text-white hover:cursor-pointer  underline underline-offset-8 decoration-white hover:decoration-core hover:text-core"
                                                    onClick={() => {
                                                        toast.clearAll();
                                                        navigate(`/transaction-history`);
                                                    }}
                                                >
                                                    {"View transaction"}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                {content?.recipientAddress && (
                                    <div className="flex w-fit overflow-auto mx-auto gap-2 mt-2 rounded-full  border border-gray-600 px-3 py-2 ">
                                        <span className="text-gray-light text-xs">Recipient:</span>
                                        <span className="text-white text-xs  max-w-[180px] md:max-w-full truncate ">
                                            {content?.recipientAddress}
                                        </span>
                                    </div>
                                )}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default ToastWrapper;
