/* eslint-disable @typescript-eslint/no-unused-vars */
import { Fragment, memo, useState } from "react";
import { useGlobalDispatch, useGlobalState } from "states/globalContext";
import { useConnectWallet } from "@web3-onboard/react";
import { useMutation } from "@tanstack/react-query";
import { Dialog, Transition } from "@headlessui/react";
import { toSliceAddress } from "utils/convert";
import Image from "ui-components/Image";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import CloseIcon from "assets/svgs/close.svg";
import { useBitcoinWallet, AddressType, Wallet } from "states/BitcoinWalletContextProvider";
import useNotification from "utils/useNotification";
import { getErrorMessage } from "utils/errorDic";
import { setUser, trackEvent } from "utils/analytics";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { isTestnet } from "utils/configs";
interface WalletOptionsProps {
    open: boolean;
}

const WalletOptions = ({ open }: WalletOptionsProps) => {
    const bitcoinWalletOptions = isTestnet
        ? [
              {
                  type: AddressType.Unisat,
                  image: "unisat",
                  title: "Unisat",
              },
          ]
        : [
              {
                  type: AddressType.Unisat,
                  image: "unisat",
                  title: "Unisat",
              },
              {
                  type: AddressType["XVerse-p2sh"],
                  image: "xverse",
                  title: "Xverse",
                  sub: [
                      // {
                      //     type: AddressType["XVerse-p2tr"],
                      //     image: "xverse",
                      //     title: "P2TR (Taproot)",
                      // },
                      {
                          type: AddressType["XVerse-p2sh"],
                          image: "xverse",
                          // title: "P2SH (Nested SegWit)",
                          //   title: `Nested SegWit (starts with "3")`,
                          title: `Nested SegWit`,
                      },
                      {
                          type: AddressType["XVerse-p2wpkh"],
                          image: "xverse",
                          // title: "P2WPKH (Native SegWit)",
                          //   title: `Native SegWit (starts with "bc1")`,
                          title: `Native SegWit`,
                      },
                  ],
              },
              {
                  type: AddressType["MagicEden-p2wpkh"],
                  image: "magiceden",
                  title: "MagicEden",
                  sub: [
                      {
                          type: AddressType["MagicEden-p2tr"],
                          image: "magiceden",
                          //   title: "P2TR (Taproot)",
                          title: "Taproot",
                      },
                      //   {
                      //       type: AddressType["MagicEden-p2sh"],
                      //       image: "magiceden",
                      //       // title: "P2SH (Nested SegWit)",
                      //       title: `Nested SegWit (starts with "3")`,
                      //   },
                      {
                          type: AddressType["MagicEden-p2wpkh"],
                          image: "magiceden",
                          // title: "P2WPKH (Native SegWit)",
                          //   title: `Native SegWit (starts with "bc1")`,
                          title: `Native SegWit`,
                      },
                  ],
              },
              {
                  type: AddressType.OKX,
                  image: "okx",
                  title: "OKX",
              },
              {
                  type: AddressType.Phantom,
                  image: "phantom",
                  title: "Phantom",
                  sub: [
                      {
                          type: AddressType["Phantom-p2tr"],
                          image: "phantom",
                          //   title: "P2TR (Taproot)",
                          title: "Taproot",
                      },
                      {
                          type: AddressType["Phantom-p2wpkh"],
                          image: "phantom",
                          //   title: "P2WPKH (Native SegWit)",
                          title: "Native SegWit",
                      },
                  ],
              },
              {
                  type: AddressType.Leather,
                  image: "leather",
                  title: "Leather",
                  sub: [
                      {
                          type: AddressType["Leather-p2tr"],
                          image: "leather",
                          //   title: "P2TR (Taproot)",
                          title: "Taproot",
                      },
                      {
                          type: AddressType["Leather-p2wpkh"],
                          image: "leather",
                          // title: "P2WPKH (Native SegWit)",
                          //   title: `Native SegWit (starts with "bc1")`,
                          title: `Native SegWit`,
                      },
                  ],
              },
              {
                  type: AddressType.Snap,
                  image: "metamask",
                  title: "MetaMask (Snap)",
                  sub: [
                      {
                          type: AddressType["Snap-p2wpkh"],
                          image: "metamask",
                          // title: "P2WPKH (Native SegWit)",
                          //   title: `Native SegWit (starts with "bc1")`,
                          title: `Native SegWit`,
                      },
                      {
                          type: AddressType["Snap-p2tr"],
                          image: "metamask",
                          //   title: "P2TR (Taproot)",
                          title: "Taproot",
                      },
                      {
                          type: AddressType["Snap-p2pkh"],
                          image: "metamask",
                          //   title: "P2PKH (Legacy)",
                          title: "Legacy",
                      },
                  ],
              },

              {
                  type: AddressType.XDefi,
                  image: "xdefi",
                  title: "XDEFI",
                  sub: [
                      {
                          type: AddressType["XDefi-p2wpkh"],
                          image: "xdefi",
                          // title: "P2WPKH (Native SegWit)",
                          //   title: `Native SegWit (starts with "bc1")`,
                          title: `Native SegWit`,
                      },
                      // {
                      //     type: AddressType["XDefi-p2pkh"],
                      //     image: "xdefi",
                      //     title: "P2PKH (Legacy)",
                      // },
                  ],
              },

              // {
              //     type: AddressType.Trezor,
              //     image: "trezor",
              //     title: "Trezor",
              //     sub: [
              //         {
              //             type: AddressType["Trezor-p2wpkh"],
              //             image: "trezor",
              //             title: "P2WPKH (Native SegWit)",
              //         },
              //         {
              //             type: AddressType["Trezor-p2pkh"],
              //             image: "trezor",
              //             title: "P2PKH (Legacy)",
              //         },
              //     ],
              // },
              // {
              //     type: AddressType.Liquality,
              //     image: "liquality",
              //     title: "Liquality",
              // },
          ];
    const bitcoinWallet = useBitcoinWallet();
    const bitcoinWalletAddress = bitcoinWallet ? bitcoinWallet?.wallet?.address : null;

    const [{ wallet }, connect, disconnect] = useConnectWallet();
    const [tonConnectUI] = useTonConnectUI();
    const account = wallet?.accounts[0]?.address?.toLowerCase();
    const accountTON = useTonAddress(true);

    const [walletType, setWalletType] = useState(undefined);
    const globalDispatch = useGlobalDispatch();
    const { walletOptions } = useGlobalState();

    const notification = useNotification();

    const handleWallet = () => {
        if (wallet) {
            disconnect(wallet).then(() => {
                window.localStorage.setItem("USER_DISCONNECTED_WALLET", "true");
            });
            trackEvent({
                category: "wallet",
                action: "disconnect_wallet",
                properties: [{ type: "evm" }],
            });
        } else {
            trackEvent({
                category: "wallet",
                action: "connect_wallet",
                properties: [{ type: "evm" }],
            });
            connect().then((wallets: any) => {
                window.localStorage.removeItem("USER_DISCONNECTED_WALLET");
                wallets[0] &&
                    wallets[0]?.accounts[0] &&
                    setUser({ userWalletAddress: wallets[0]?.accounts[0]?.address });
                trackEvent({
                    category: "wallet",
                    action: "connected_wallet",
                    properties: [{ type: wallets[0].label }],
                });
                handleClose();
            });
        }
    };
    const handleTonWallet = () => {
        if (tonConnectUI.account) {
            tonConnectUI.disconnect().then(() => {
                window.localStorage.setItem("USER_DISCONNECTED_WALLET", "true");
            });
            trackEvent({
                category: "wallet",
                action: "disconnect_wallet",
                properties: [{ type: "ton" }],
            });
        } else {
            trackEvent({
                category: "wallet",
                action: "connect_wallet",
                properties: [{ type: "ton" }],
            });
            tonConnectUI.openModal().then((wallets: any) => {
                // window.localStorage.removeItem("USER_DISCONNECTED_WALLET");
                // wallets[0] &&
                //     wallets[0]?.accounts[0] &&
                //     setUser({ userWalletAddress: wallets[0]?.accounts[0]?.address });
                // trackEvent({
                //     category: "wallet",
                //     action: "connected_wallet",
                //     properties: [{ type: wallets[0].label }],
                // });
                handleClose();
            });
        }
    };

    const mutationConnectWallet = useMutation((walletType: any): any => {
        return bitcoinWallet.connect(walletType);
    });

    const handleBitcoinWallet = (walletType: any) => {
        if (bitcoinWallet?.wallet?.address) {
            bitcoinWallet.disconnect().then(() => {});
            trackEvent({
                category: "wallet",
                action: "disconnect_wallet",
                properties: [{ type: "bitcoin" }],
            });
        } else {
            trackEvent({
                category: "wallet",
                action: "connect_wallet",
                properties: [{ type: "bitcoin" }],
            });
            mutationConnectWallet.mutate(walletType, {
                onSuccess: (wallet: any) => {
                    handleClose();
                    setUser({ userWalletAddress: wallet?.address });
                    trackEvent({
                        category: "wallet",
                        action: "connected_wallet",
                        properties: [{ type: wallet.type }],
                    });
                },
                onError: (error) => {
                    notification.error(getErrorMessage(error));
                    mutationConnectWallet.reset();
                },
            });
        }
    };
    const handleClose = () => {
        globalDispatch({ type: "setWalletOptions", value: { show: false, value: walletOptions.value } });
    };
    const btcAddressSlice =
        bitcoinWalletAddress && bitcoinWalletAddress?.length ? `${toSliceAddress(bitcoinWalletAddress)}` : null;
    const addressSlice = account && account?.length ? `${toSliceAddress(account)}` : null;
    const addressSliceTON = accountTON && accountTON?.length ? `${toSliceAddress(accountTON)}` : null;

    const allConditions = walletOptions.value?.includes("bitcoin-evm-ton");
    const anyWalletIsConnected = !!account || !!accountTON || !!bitcoinWalletAddress;
    const showEVM = allConditions
        ? anyWalletIsConnected
            ? !!account
            : !accountTON && !bitcoinWalletAddress
        : walletOptions.value?.includes("evm");
    const showTON = isTestnet
        ? allConditions
            ? anyWalletIsConnected
                ? !!accountTON
                : !account && !bitcoinWalletAddress
            : walletOptions.value?.includes("ton")
        : false;
    const showBitcoin = allConditions
        ? anyWalletIsConnected
            ? !!bitcoinWalletAddress
            : !account && !accountTON
        : walletOptions.value?.includes("bitcoin");
    return (
        <Transition appear show={open} as={Fragment}>
            <Dialog as="div" className="relative z-6" onClose={handleClose}>
                <div className="fixed inset-0 backdrop-blur-custom bg-secondary/70" aria-hidden="true" />
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center w-full">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className={classNames(
                                    " w-full  transform  rounded-2xl bg-ground p-6 text-left align-middle shadow-xl transition-all",
                                    "md:max-w-[500px]",
                                )}
                            >
                                <div className="text-primary  p-2 w-full text-lg ">Connect wallet</div>
                                <div className="mt-1 flex flex-col  justify-center  w-full ">
                                    <div className="text-primary-light px-2 py-1 w-full ">
                                        Choose your wallet to connect.
                                    </div>

                                    {/* <div className="text-primary-light p-2 w-full ">
                                        {showEVM && "Choose your Web3  wallet to connect."}
                                    </div> */}
                                    {showEVM && (
                                        <button
                                            className="pl-2  text-sm py-3 cursor-pointer text-primary "
                                            onClick={handleWallet}
                                        >
                                            {wallet ? (
                                                <span className="flex items-center justify-between">
                                                    <span className="flex items-center">
                                                        <Image
                                                            src="/images/metamask.svg"
                                                            className=""
                                                            width="23"
                                                            height="23"
                                                        />{" "}
                                                        <span className="px-2">{addressSlice} </span>{" "}
                                                    </span>
                                                    <span className="flex items-center">
                                                        Disconnect
                                                        <ChevronRightIcon className={` h-5 w-5 `} />
                                                    </span>
                                                </span>
                                            ) : (
                                                <span className="flex items-center justify-between">
                                                    <span className="flex items-center">
                                                        <Image
                                                            src="/images/metamask.svg"
                                                            className=""
                                                            width="23"
                                                            height="23"
                                                        />{" "}
                                                        <span className="px-2">EVM wallet </span>{" "}
                                                    </span>
                                                    <span className="flex items-center">
                                                        <ChevronRightIcon className={` h-5 w-5 `} />
                                                    </span>
                                                </span>
                                            )}
                                        </button>
                                    )}
                                    {/* <div className="text-primary-light p-2 w-full ">
                                        {showTON && "Choose your TON  wallet to connect."}
                                    </div> */}
                                    {showTON && (
                                        <button
                                            className="pl-2  text-sm py-3 cursor-pointer text-primary "
                                            onClick={handleTonWallet}
                                        >
                                            {tonConnectUI.account?.address ? (
                                                <span className="flex items-center justify-between">
                                                    <span className="flex items-center">
                                                        <Image
                                                            src="/images/ton.png"
                                                            className=""
                                                            width="23"
                                                            height="23"
                                                        />{" "}
                                                        <span className="px-2">{addressSliceTON} </span>{" "}
                                                    </span>
                                                    <span className="flex items-center">
                                                        Disconnect
                                                        <ChevronRightIcon className={` h-5 w-5 `} />
                                                    </span>
                                                </span>
                                            ) : (
                                                <span className="flex items-center justify-between">
                                                    <span className="flex items-center">
                                                        <Image
                                                            src="/images/ton.png"
                                                            className=""
                                                            width="23"
                                                            height="23"
                                                        />{" "}
                                                        <span className="px-2">TON wallet </span>{" "}
                                                    </span>
                                                    <span className="flex items-center">
                                                        <ChevronRightIcon className={` h-5 w-5 `} />
                                                    </span>
                                                </span>
                                            )}
                                        </button>
                                    )}
                                    {/* <div className="text-primary-light   p-2 w-full ">
                                        {showBitcoin && "Choose your Bitcoin wallet to connect."}{" "}
                                    </div> */}
                                    {showBitcoin && (
                                        <div className=" flex flex-col mt-2  text-sm text-primary  rounded-md  w-full ">
                                            {bitcoinWalletAddress || mutationConnectWallet.isLoading ? (
                                                <button
                                                    className="pl-2  py-3 cursor-pointer"
                                                    onClick={() => {
                                                        !mutationConnectWallet.isLoading &&
                                                            bitcoinWalletAddress &&
                                                            handleBitcoinWallet(bitcoinWallet?.wallet.type);
                                                    }}
                                                >
                                                    {bitcoinWallet?.wallet?.address &&
                                                    !mutationConnectWallet.isLoading ? (
                                                        <span className="flex items-center justify-between">
                                                            <span className="flex items-center">
                                                                <Image
                                                                    src={`/images/${bitcoinWallet?.wallet?.icon}.svg`}
                                                                    className=""
                                                                    width="23"
                                                                    height="23"
                                                                />{" "}
                                                                <span className="px-2">{btcAddressSlice} </span>{" "}
                                                            </span>
                                                            <span className="flex items-center">
                                                                Disconnect
                                                                <ChevronRightIcon className={` h-5 w-5 `} />
                                                            </span>
                                                        </span>
                                                    ) : (
                                                        `${mutationConnectWallet.isLoading ? "Connecting..." : ""}`
                                                    )}
                                                </button>
                                            ) : (
                                                <>
                                                    {bitcoinWalletOptions.map((option: any) => (
                                                        <Fragment key={option.type}>
                                                            <button
                                                                className="pl-2  py-3 cursor-pointer hover:bg-ground-lighter rounded"
                                                                onClick={() => {
                                                                    !option?.sub && handleBitcoinWallet(option.type);
                                                                    option?.sub &&
                                                                        (walletType && walletType == option.type
                                                                            ? setWalletType(undefined)
                                                                            : setWalletType(option.type));
                                                                }}
                                                            >
                                                                {bitcoinWallet?.wallet?.address &&
                                                                bitcoinWallet?.wallet?.type === option.type ? (
                                                                    <span className="flex items-center justify-between">
                                                                        <span className="flex items-center">
                                                                            <Image
                                                                                src={`/images/${option.image}.svg`}
                                                                                className=""
                                                                                width="23"
                                                                                height="23"
                                                                            />{" "}
                                                                            <span className="px-2">
                                                                                {option.title}{" "}
                                                                            </span>{" "}
                                                                        </span>
                                                                        <span className="flex items-center">
                                                                            Disconnect
                                                                            <ChevronRightIcon className={` h-5 w-5 `} />
                                                                        </span>
                                                                    </span>
                                                                ) : (
                                                                    <>
                                                                        <span className="flex items-center justify-between">
                                                                            <span className="flex items-center">
                                                                                <Image
                                                                                    src={`/images/${option.image}.svg`}
                                                                                    className=""
                                                                                    width="23"
                                                                                    height="23"
                                                                                />{" "}
                                                                                <span className="px-2">
                                                                                    {option.title}{" "}
                                                                                </span>{" "}
                                                                            </span>
                                                                            <span className="flex items-center">
                                                                                <ChevronRightIcon
                                                                                    className={` h-5 w-5 `}
                                                                                />
                                                                            </span>
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </button>
                                                            {walletType &&
                                                                option.type === walletType &&
                                                                (option?.sub || [])?.map((optionSub: any) => (
                                                                    <button
                                                                        key={optionSub.title}
                                                                        className="pl-12  pb-3  pt-3 cursor-pointer flex flex-col items-start justify-center hover:bg-ground-lighter rounded"
                                                                        onClick={() => {
                                                                            handleBitcoinWallet(optionSub.type);
                                                                        }}
                                                                    >
                                                                        {optionSub.title}
                                                                    </button>
                                                                ))}
                                                        </Fragment>
                                                    ))}
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <button
                                    role="button"
                                    className="absolute top-4 right-3 p-1 cursor-pointer"
                                    onClick={handleClose}
                                >
                                    <img src={CloseIcon} height="30" width="30" />
                                </button>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};
WalletOptions.defaultProps = {};
export default memo(WalletOptions);
