import Image from "ui-components/Image";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { isTestnet, projectName } from "utils/configs";
import { useTeleSwap } from "services/useTeleSwap";
import { useQuery } from "@tanstack/react-query";

export default function PromotionSlider() {
    const { getBanners } = useTeleSwap();
    const { data: banners } = useQuery({
        queryKey: [`getBanners`],
        queryFn: () => {
            return getBanners();
        },
        refetchOnWindowFocus: false,
        refetchInterval: false,
        retry: false,
    });
    if (!banners || !banners.length) return null;
    return (
        <div
            className={classNames(
                "flex justify-center items-center  px-4  md:p-0 md:pb-4  md:max-w-[1000px]  bg-no-repeat bg-center rounded-[0.625rem] md:rounded-[1.875rem] w-full  h-auto",
            )}
        >
            <NavLink
                className="flex items-center min-h-[92px] text-primary pt-0 w-full"
                // target="_blank"
                to={banners[0]?.link}
            >
                <Image
                    src={banners[0]?.image}
                    alt="Poster"
                    loading="lazy"
                    className="object-fill object-center  w-full rounded-[0.3rem] md:rounded-[0.625rem] !relative"
                />
            </NavLink>
        </div>
    );
}
