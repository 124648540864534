export default function Image({ ...res }) {
    return (
        <img
            onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = currentTarget?.currentSrc?.includes(".COM")
                    ? "/images/colorful/dotcom.png"
                    : (currentTarget.src = currentTarget?.currentSrc?.includes("usdc.e.png")
                          ? "https://statics.teleportdao.xyz/coin/USDC.png"
                          : (currentTarget.src = currentTarget?.currentSrc?.includes("WBNB")
                                ? "https://statics.teleportdao.xyz/coin/BNB.png"
                                : currentTarget?.currentSrc));
            }}
            {...res}
            src={
                res?.src?.includes("WTST.png")
                    ? "https://statics.teleportdao.xyz/coin/TST.png"
                    : res?.src?.includes("ETHEREUM.png")
                      ? "/images/colorful/ethereum.png"
                      : res?.src?.includes("BASE.png")
                        ? "/images/colorful/base.png"
                        : res?.src?.includes("POLYGON.png")
                          ? "/images/colorful/polygon.png"
                          : res?.src?.includes("ARBITRUM.png")
                            ? "/images/colorful/arbitrum.png"
                            : res?.src?.includes("BINANCE.png")
                              ? "/images/colorful/bnb.png"
                              : res?.src?.includes("OPTIMISM.png")
                                ? "/images/colorful/optimism.png"
                                : res?.src?.includes("BSQUARED.png")
                                  ? "/images/colorful/bsquared.png"
                                  : res?.src?.includes("BOB.png")
                                    ? "/images/colorful/bob.png"
                                    : res?.src?.includes("R.png")
                                      ? "https://statics.teleportdao.xyz/coin/DOG.png"
                                      : res?.src?.includes("BTCB.png")
                                        ? "https://statics.teleportdao.xyz/coin/BTC.png"
                                        : res?.src?.includes("colorful/BTC.png")
                                          ? "https://statics.teleportdao.xyz/coin/BTC.png"
                                          : res?.src?.includes("BITCOIN.png")
                                            ? "https://statics.teleportdao.xyz/coin/BTC.png"
                                            : res?.src?.includes("MEME.png")
                                              ? "/images/colorful/meme.png"
                                              : res?.src?.includes("TRIO.png")
                                                ? "/images/colorful/trio.png"
                                                : res?.src?.includes(".COM.png")
                                                  ? "/images/colorful/dotcom.png"
                                                  : res?.src?.includes("usdc.e.png")
                                                    ? "https://statics.teleportdao.xyz/coin/USDC.png"
                                                    : res?.src?.includes("WBNB")
                                                      ? "https://statics.teleportdao.xyz/coin/BNB.png"
                                                      : res?.src
            }
        />
    );
}
